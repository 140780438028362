import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Radio,
    Popconfirm,
    notification,
    DatePicker,
    Select,
    Tag,
    InputNumber,
    // Switch,
    Form,
    message,
} from 'antd'
import {
    // CheckOutlined,
    // CloseOutlined,
    EditTwoTone,
    LoadingOutlined,
    ReloadOutlined,
} from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faExternalLinkAlt,
    faTrashAlt,
    faThumbtack,
} from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'
import { useNavigate, useLocation } from 'react-router-dom'
import { Utils } from '../JS/Utils'
import { PlayCircleTwoTone } from '@ant-design/icons'
import ReactAudioPlayer from 'react-audio-player'
import dayjs from 'dayjs'
import { Link } from '@mui/material'
import Linkify from 'linkify-react'
import ILAttachmentFileList, {
    processClipboardAttachment,
} from '../ILShareComponents/ILAttachmentFileList'

const OPEN = 'open'
const UNASSIGNED = 'unassigned'
const FOLLOW_UP = 'followup'
const CLOSED = 'closed'

const COMMENT_FILTER_ALL = 'COMMENT_FILTER_ALL'
const COMMENT_FILTER_PINNED = 'COMMENT_FILTER_PINNED'

const LEAD_TYPE_FUTURE_PROSPECT = 'Future Prospect'

function CartLeads() {
    const { TextArea } = Input
    const { RangePicker } = DatePicker
    const { Option } = Select

    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const filterData = params.get('filter')
    const streamCourse = params.get('course')
    const streamLevel = params.get('level')
    const filterLead = params.get('leadType')
    const filterLeadTypeEntry = params.get('hasLeadTypeEntry')
    const startDateQuery = params.get('startDate')
    const endDateQuery = params.get('endDate')
    const asignee = params.get('asignee')
    const attempt = params.get('attempt')
    const minCart = params.get('minCartValue')
    const maxCart = params.get('maxCartValue')

    const [loading, setLoading] = useState(false)
    const [nextUrl, setNextUrl] = useState()
    const [data, setData] = useState([])
    const [addCommentModalVisible, setAddCommentModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [allCommentsModalVisible, setAllCommentsModalVisible] =
        useState(false)
    const [allComments, setAllComments] = useState([])
    const [allCommentsPinned, setAllCommentsPinned] = useState([])
    const [selectedCommentFilter, setSelectedCommentFilter] =
        useState(COMMENT_FILTER_ALL)
    const [filter, setFilter] = useState(filterData || UNASSIGNED)
    const [editCommentVisible, setEditCommentVisible] = useState(false)
    const [selectedComment, setSelectedComment] = useState({})
    const [editReminderVisible, setEditReminderVisible] = useState(false)
    const [reminderDate, setReminderDate] = useState('')
    const [startDate, setStartDate] = useState(startDateQuery || '')
    const [endDate, setEndDate] = useState(endDateQuery || '')
    const [minCartValue, setMinCartValue] = useState(minCart || 0)
    const [maxCartValue, setMaxCartValue] = useState(maxCart || 0)
    const [closingCommentModalVisible, setClosingCommentModalVisible] =
        useState(false)
    const [adminUsers, setAdminUsers] = useState([])
    const [selectedAsignee, setSelectedAsignee] = useState(asignee || '')
    const [assignToModalVisible, setAssignToModalVisible] = useState(false)
    const [openStatusTypes, setOpenStatusTypes] = useState([])
    const [filterSubTypes, setFilterSubTypes] = useState([])
    const [closedStatusTypes, setClosedStatusTypes] = useState([])
    const [leadSubTypes, setLeadSubTypes] = useState([])
    const [openModalVisible, setOpenModalVisible] = useState(false)
    const [updateLeadOpenTypeVisible, setUpdateLeadOpenTypeVisible] =
        useState(false)
    const [updateModalVisible, setUpdateModalVisible] = useState(false)
    const [openOrClose, setOpenOrClose] = useState(true)
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [bulkAssignModalVisible, setBulkAssignModalVisible] = useState(false)
    const [openOrCloseFilterType, setOpenOrCloseFilterType] = useState(true)
    const [filterLeadType, setFilterLeadType] = useState(filterLead || '')
    const [filterLeadSubType, setFilterLeadSubType] = useState('')
    const [filterHasLeadTypeEntry, setFilterHasLeadTypeEntry] = useState(
        filterLeadTypeEntry || ''
    )
    const [leadSources, setLeadSources] = useState([])
    const [addCallEntryModalVisible, setAddCallEntryModalVisible] =
        useState(false)
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [selectedCourse, setSelectedCourse] = useState('')
    const [filterLevelData, setFilterLevelData] = useState([])
    const [streamCourseFilter, setStreamCourseFilter] = useState(
        streamCourse || ''
    )
    const [streamLevelFilter, setStreamLevelFilter] = useState(
        streamLevel || ''
    )
    const [attempts, setAttempts] = useState([])
    const [selectedAttempt, setSelectedAttempt] = useState(attempt || '')
    const [followUpLeadType, setFollowUpLeadType] = useState('open')
    const [phoneEndingWith, setPhoneEndingWith] = useState('')
    const [emailStartingWith, setEmailStartingWith] = useState('')
    const [includeActionLeads] = useState(true)
    const [pinUnpinLoading, setPinUnpinLoading] = useState(false)
    const [leadsCount, setLeadsCount] = useState()
    const [highProspectModalVisible, setHighProspectModalVisible] =
        useState(false)
    const [expectedSaleDate, setExpectedSaleDate] = useState('')
    const [highProspectsFilter, setHighProspectsFilter] = useState('all')

    const [callUserLoading, setCallUserLoading] = useState(false)
    const [playAudioModalVisible, setPlayAudioModalVisible] = useState(false)
    const [audioUrl, setAudioUrl] = useState('')
    const [loadingRefreshCloudPhoneCall, setLoadingRefreshCloudPhoneCall] =
        useState(false)
    const [loadingComments, setLoadingComments] = useState(false)
    const [commentAttachmentFileList, setCommentAttachmentFileList] = useState(
        []
    )
    const [selectedUpdateLeadType, setSelectedUpdateLeadType] = useState()

    const [form] = Form.useForm()
    const inputRef = useRef(null)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filter,
        endDate,
        selectedAsignee,
        filterLeadType,
        filterLeadSubType,
        streamCourseFilter,
        streamLevelFilter,
        selectedAttempt,
        followUpLeadType,
        includeActionLeads,
        highProspectsFilter,
        filterHasLeadTypeEntry,
    ])

    useEffect(() => {
        getFiltersData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (streamCourseFilter && streamData) {
            streamData.forEach((item) => {
                if (item.course === streamCourseFilter) {
                    setFilterLevelData(item.levels)
                    return
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streamData])

    useEffect(() => {
        if (!filterData) {
            return
        }
        setFilter(filterData)
    }, [filterData])

    useEffect(() => {
        if (location.pathname !== '/leads/internal') return

        const queryParams = new URLSearchParams()
        if (filter) queryParams.set('filter', filter)
        if (streamCourseFilter) queryParams.set('course', streamCourseFilter)
        if (streamCourseFilter) queryParams.set('level', streamLevelFilter)
        if (filterLeadType) queryParams.set('leadType', filterLeadType)
        if (selectedAsignee) queryParams.set('asignee', selectedAsignee)
        if (startDate && endDate.length > 0)
            queryParams.set('startDate', startDate)
        if (endDate && endDate.length > 0) queryParams.set('endDate', endDate)
        if (selectedAttempt) queryParams.set('attempt', selectedAttempt)
        if (minCartValue) queryParams.set('minCartValue', minCartValue)
        if (maxCartValue) queryParams.set('maxCartValue', maxCartValue)
        navigate({ search: queryParams.toString() })
        // eslint-disable-next-line
    }, [
        filter,
        streamCourseFilter,
        streamLevelFilter,
        selectedAsignee,
        filterLeadType,
        endDate,
        startDate,
        navigate,
        maxCartValue,
        minCartValue,
        selectedAttempt,
    ])

    useEffect(() => {
        onChangeFilterLeadType(filterLead)
        //eslint-disable-next-line
    }, [filterLead])

    useEffect(() => {
        setTimeout(() => {
            if (addCommentModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addCommentModalVisible, inputRef])

    const getFiltersData = () => {
        RestApi.doGet(GLOBAL.URL.LEADS.GET_LEADS_FILTERS_DATA).then((res) => {
            setAdminUsers(res.data.adminUsers)
            setOpenStatusTypes(res.data.openStatusTypes)
            setClosedStatusTypes(res.data.closedStatusTypes)
            setStreamData(JSON.parse(res.data.streamData))
            setAttempts(res.data.attempts)
        })
    }

    const fetchData = () => {
        setLoading(true)
        setData([])

        var url =
            GLOBAL.URL.GET_CART_LEADS +
            '?filter=' +
            filter +
            '&startDate=' +
            startDate +
            '&endDate=' +
            endDate +
            '&assignToUserId=' +
            selectedAsignee +
            '&minCartValue=' +
            minCartValue +
            '&maxCartValue=' +
            maxCartValue +
            '&leadType=' +
            filterLeadType +
            '&leadSubType=' +
            filterLeadSubType +
            '&stream=' +
            streamCourseFilter +
            '&level=' +
            streamLevelFilter +
            '&attempt=' +
            selectedAttempt +
            '&followUpLeadType=' +
            followUpLeadType +
            '&phoneEndingWith=' +
            phoneEndingWith +
            '&emailStartingWith=' +
            emailStartingWith +
            '&includeActionLeads=' +
            (includeActionLeads ? 'yes' : 'no') +
            '&highProspectsFilter=' +
            highProspectsFilter +
            '&hasLeadTypeEntry=' +
            (filterHasLeadTypeEntry ? filterHasLeadTypeEntry : '')

        RestApi.doGet(url)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData(res.data)
                setLeadsCount(res.leadsCount)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageData = () => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const openLead = () => {
        form.validateFields(['type'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.userId = selectedRecord.userId
                payload.type = values.type

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.OPEN_CART_LEAD, formData)
                    .then((response) => {
                        var fullData = data
                        fullData.forEach((item) => {
                            if (item.userId === selectedRecord.userId) {
                                item.openType = values.type
                                item.open = !item.open
                                return
                            }
                        })

                        setData(fullData)
                        notification['success']({
                            message: response.data,
                        })

                        hideOpenModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const updateLeadOpenType = () => {
        form.validateFields(['type', 'subType'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.userId = selectedRecord.userId
                payload.type = values.type
                payload.subType = values.subType ? values.subType : ''

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.UPDATE_CART_LEAD_OPEN_TYPE, formData)
                    .then((response) => {
                        var fullData = data
                        fullData.forEach((item) => {
                            if (item.userId === selectedRecord.userId) {
                                item.openType = values.type
                                return
                            }
                        })

                        setData(fullData)
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateLeadOpenType()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const closeLead = () => {
        form.validateFields(['comments', 'type'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.userId = selectedRecord.userId
                payload.comments = values.comments
                payload.type = values.type

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.CLOSE_CART_LEAD, formData)
                    .then((response) => {
                        RestApi.doGet(
                            GLOBAL.URL.GET_CART_LEAD_LATEST_COMMENTS +
                                '?userId=' +
                                selectedRecord.userId
                        )
                            .then((latestComment) => {
                                var fullData = data
                                fullData.forEach((item) => {
                                    if (item.userId === selectedRecord.userId) {
                                        item.closedType = values.type
                                        item.latestComment = latestComment.data
                                        item.open = !item.open
                                        return
                                    }
                                })

                                setData(fullData)
                            })
                            .finally(() => {
                                hideClosingCommentModal()
                                notification['success']({
                                    message: response.data,
                                })
                                setLoading(false)
                            })
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const assignLead = () => {
        form.validateFields(['assignToUserId'])
            .then((values) => {
                setLoading(true)

                values.userId = selectedRecord.userId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.ASSIGN_CART_LEAD, formData)
                    .then((response) => {
                        RestApi.doGet(
                            GLOBAL.URL.GET_CART_LEAD + selectedRecord.userId
                        )
                            .then((res) => {
                                var fullData = data
                                fullData.forEach((item) => {
                                    if (item.userId === selectedRecord.userId) {
                                        item.assignedTo = res.data.assignedTo
                                        return
                                    }
                                })

                                setData(fullData)
                                hideAssignToModal()
                                notification['success']({
                                    message: response.data,
                                })
                            })
                            .finally(() => {
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const addComment = () => {
        form.validateFields(['comments'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.userId = selectedRecord.userId
                payload.comments = values.comments

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                commentAttachmentFileList.forEach((file) => {
                    formData.append('file', file.originFileObj)
                })

                RestApi.doPost(GLOBAL.URL.ADD_COMMENTS_TO_CART_LEAD, formData)
                    .then((response) => {
                        RestApi.doGet(
                            GLOBAL.URL.GET_CART_LEAD_LATEST_COMMENTS +
                                '?userId=' +
                                selectedRecord.userId
                        )
                            .then((latestComment) => {
                                var fullData = data
                                fullData.forEach((item) => {
                                    if (item.userId === selectedRecord.userId) {
                                        item.latestComment = latestComment.data
                                        return
                                    }
                                })

                                setData(fullData)
                                setCommentAttachmentFileList([])
                            })
                            .finally(() => {
                                closeAddCommentModal()
                                notification['success']({
                                    message: response.data,
                                })
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const openAddCommentModal = (record) => {
        form.resetFields()
        setAddCommentModalVisible(true)
        setSelectedRecord(record)
    }

    const closeAddCommentModal = () => {
        setAddCommentModalVisible(false)
        setSelectedRecord({})
        setCommentAttachmentFileList([])
    }

    const openAllCommentsModal = (record) => {
        setLoading(true)
        setSelectedRecord(record)
        getCartLeadAllComments(record)
    }

    const getCartLeadAllComments = (record) => {
        setLoadingComments(true)
        RestApi.doGet(
            GLOBAL.URL.GET_CART_LEAD_ALL_COMMENTS + '?userId=' + record.userId
        )
            .then((res) => {
                var pinnedComments = []
                res.data.forEach((comment) => {
                    if (comment.pinned) {
                        pinnedComments.push(comment)
                    }
                })

                setAllComments(res.data)
                setAllCommentsPinned(pinnedComments)

                setAllCommentsModalVisible(true)

                if (!pinnedComments.length) {
                    setSelectedCommentFilter(COMMENT_FILTER_ALL)
                }
            })
            .finally(() => {
                setLoading(false)
                setLoadingComments(false)
            })
    }

    const hideAllCommentsModal = (record) => {
        setAllCommentsModalVisible(false)
        setAllComments([])
    }

    const addCommentFromAllCommentsModal = () => {
        hideAllCommentsModal()
        openAddCommentModal(selectedRecord)
    }

    const deleteComment = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_CART_LEAD_COMMENTS, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCartLeadAllComments(selectedRecord)

                RestApi.doGet(
                    GLOBAL.URL.GET_CART_LEAD_LATEST_COMMENTS +
                        '?userId=' +
                        selectedRecord.userId
                ).then((latestComment) => {
                    var fullData = data
                    fullData.forEach((item) => {
                        if (item.userId === selectedRecord.userId) {
                            item.latestComment = latestComment.data
                            return
                        }
                    })

                    setData(fullData)
                })
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const editComment = (record) => {
        setEditCommentVisible(true)
        setSelectedComment(record)
        form.setFieldsValue({ comments: record.comments })
    }

    const updateComments = () => {
        form.validateFields(['comments'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.id = selectedComment.id
                payload.comments = values.comments

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.UPDATE_CART_LEAD_COMMENTS, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        setEditCommentVisible(false)
                        getCartLeadAllComments(selectedRecord)

                        RestApi.doGet(
                            GLOBAL.URL.GET_CART_LEAD_LATEST_COMMENTS +
                                '?userId=' +
                                selectedRecord.userId
                        ).then((latestComment) => {
                            var fullData = data
                            fullData.forEach((item) => {
                                if (item.userId === selectedRecord.userId) {
                                    item.latestComment = latestComment.data
                                    return
                                }
                            })

                            setData(fullData)
                        })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
    }

    const callLead = (record) => {
        setSelectedRecord(record)
        setCallUserLoading(true)

        var payload = {}
        payload.userId = record.userId

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.CLOUD_PHONE.CALL_INTERNAL_LEAD, postBody)
            .then((response) => {
                message.success(response.data)

                RestApi.doGet(
                    GLOBAL.URL.GET_CART_LEAD_LATEST_COMMENTS +
                        '?userId=' +
                        record.userId
                ).then((latestComment) => {
                    var fullData = data
                    fullData.forEach((item) => {
                        if (item.userId === record.userId) {
                            item.latestComment = latestComment.data
                        }
                    })

                    setData(fullData)
                })
            })
            .finally(() => {
                setCallUserLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => (
                <span style={{ fontSize: 12 }}>{index + 1}</span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render: (text, record) => (
                <span>
                    <Button
                        type={record.open ? 'primary' : 'outline'}
                        onClick={() => {
                            showUpdateModal(record)
                        }}
                        size="small"
                        style={{ fontSize: 12 }}
                    >
                        {record.open ? 'Open' : 'Closed'}
                    </Button>
                    <br />
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => callLead(record)}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="default"
                            size="small"
                            style={{ marginTop: 5, fontSize: 12 }}
                            loading={callUserLoading}
                        >
                            Call Lead
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 12 }}>{record.date}</span>
                    <br />
                    {record.assignedTo && record.assignedTo.id ? (
                        <>
                            <span>
                                {record.assignedTo
                                    ? record.assignedTo.firstName
                                    : ''}
                            </span>
                        </>
                    ) : (
                        <Button
                            size="small"
                            onClick={() => {
                                showAssignToModal(record)
                            }}
                            style={{ fontSize: 10, padding: 0 }}
                            type="link"
                        >
                            Assign
                        </Button>
                    )}
                </span>
            ),
        },
        {
            title: 'Type / Cart',
            dataIndex: 'totalMrp',
            key: 'totalMrp',
            render: (text, record) => (
                <span>
                    <Tag color="purple">
                        {record.open ? record.openType : record.closedType}
                    </Tag>
                    <br />
                    <br />
                    {record.totalMrp}
                    <br />
                    <span style={{ fontSize: 10 }}>
                        {record.coupon && record.coupon.couponName
                            ? record.coupon.couponName +
                              ' (' +
                              record.coupon.discount +
                              record.coupon.type +
                              ')'
                            : ''}
                    </span>
                </span>
            ),
        },
        {
            title: 'User',
            key: 'userId',
            render: (text, record) => (
                <span>
                    <Link
                        href={'/userdetails/' + record.userId}
                        onClick={(event) => {
                            event.preventDefault()
                            window.open(
                                '/userdetails/' + record.userId,
                                '_blank'
                            )
                        }}
                        underline="none"
                    >
                        {record.name}
                        <br />
                        {record.phone ? record.isd + '-' + record.phone : ''}
                        <br />
                        <span style={{ fontSize: 10 }}>{record.email}</span>
                    </Link>

                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 10, marginLeft: 10 }}
                        onClick={() =>
                            window.open(
                                '/userdetails/' + record.userId,
                                '_blank'
                            )
                        }
                    >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </Button>
                </span>
            ),
        },
        {
            title: 'Prospect',
            key: 'prospect',
            render: (text, record) => (
                <span>
                    {record.leadCreated ? (
                        <>
                            {record.highProspectData &&
                            record.highProspectData.active ? (
                                <span>
                                    <Tag color="red">{'High Prospect'}</Tag>
                                    {record.highProspectData.expectedSale ? (
                                        <>
                                            <br />
                                            {
                                                record.highProspectData
                                                    .expectedSaleString
                                            }
                                        </>
                                    ) : null}
                                    {record.highProspectData
                                        .expectedSaleDate ? (
                                        <>
                                            <br />
                                            {
                                                record.highProspectData
                                                    .expectedSaleDate
                                            }
                                        </>
                                    ) : null}
                                </span>
                            ) : null}
                            <br />
                            <Button
                                type="link"
                                style={{ padding: 0, fontSize: 10 }}
                                onClick={() => showHighProspectModal(record)}
                            >
                                Update Prospect
                            </Button>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Comment',
            dataIndex: 'courses',
            key: 'courses',
            width: 300,
            render: (text, record) => (
                <>
                    {record?.latestComment && record.latestComment?.comments ? (
                        <div>
                            <Linkify
                                as="p"
                                options={{
                                    target: '_blank',
                                    rel: 'noopener noreferrer',
                                }}
                                style={{
                                    marginBlockStart: '0.4em',
                                    marginBlockEnd: '0.4em',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {record.latestComment.comments}
                            </Linkify>
                            <p>
                                <span style={{ fontSize: 10 }}>
                                    {' ('}
                                    {record.latestComment.commentedBy.firstName}
                                    {', '}
                                    {record.latestComment.date}
                                    {')'}
                                </span>
                            </p>
                        </div>
                    ) : null}
                    <div>
                        <Button
                            type="link"
                            onClick={() => {
                                openAddCommentModal(record)
                            }}
                            size="small"
                            style={{ fontSize: 10, padding: 0 }}
                        >
                            Add Comment
                        </Button>
                        {record.latestComment &&
                        record.latestComment.comments ? (
                            <Button
                                type="link"
                                onClick={() => {
                                    openAllCommentsModal(record)
                                }}
                                size="small"
                                style={{
                                    fontSize: 10,
                                    padding: 0,
                                    marginLeft: 10,
                                }}
                            >
                                All Comments
                            </Button>
                        ) : null}
                        <Button
                            type="link"
                            style={{ fontSize: 10, padding: 0, marginLeft: 10 }}
                            onClick={() => showAddCallEntryModal(record)}
                        >
                            Add Call Entry
                        </Button>
                    </div>
                </>
            ),
        },
        {
            title: 'Stream',
            dataIndex: 'stream',
            key: 'stream',
            render: (text, record) => (
                <span style={{ fontSize: 10 }}>
                    {record.stream ? record.stream + ' - ' + record.level : ''}
                    {record.attempt ? (
                        <>
                            <br />
                            {record.attempt}
                        </>
                    ) : (
                        ''
                    )}
                    {record.source ? (
                        <>
                            <br /> {'(' + record.source + ')'}
                        </>
                    ) : (
                        ''
                    )}
                </span>
            ),
        },
        {
            title: 'Follow Up',
            dataIndex: 'reminderDate',
            key: 'reminderDate',
            render: (text, record) => (
                <span>
                    {record.reminderDate ? (
                        <div style={{ marginBottom: 10, fontSize: 11 }}>
                            {record.reminderDate}
                        </div>
                    ) : null}
                    <div>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => showUpdateReminderDateModal(record)}
                            style={{ fontSize: 10, padding: 0 }}
                        >
                            Update
                        </Button>
                    </div>
                </span>
            ),
        },
    ]

    const allCommentsColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.date}</span>
            ),
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (text, record) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {Utils.isUserAdmin() ? (
                        <Button
                            type="link"
                            onClick={() => {
                                editComment(record)
                            }}
                            style={{ padding: 0, marginRight: 10 }}
                        >
                            <EditTwoTone style={{ fontSize: 12 }} />
                        </Button>
                    ) : null}
                    {record.pinned ? (
                        <FontAwesomeIcon
                            icon={faThumbtack}
                            style={{
                                color: '#1890ff',
                                marginRight: 10,
                            }}
                        />
                    ) : null}

                    <Linkify
                        as="p"
                        options={{
                            target: '_blank',
                            rel: 'noopener noreferrer',
                        }}
                        style={{
                            marginBlockStart: '0.4em',
                            marginBlockEnd: '0.4em',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {record.comments}
                        <br />{' '}
                        {record.cloudPhoneCall
                            ? record.cloudPhoneCall.direction +
                              '  ~  ' +
                              record.cloudPhoneCall.status +
                              (record.cloudPhoneCall.leg1Status
                                  ? '  ~  ' +
                                    record.cloudPhoneCall.leg1Status +
                                    ' / ' +
                                    (record.cloudPhoneCall.leg2Status
                                        ? record.cloudPhoneCall.leg2Status
                                        : '')
                                  : '')
                            : ''}
                    </Linkify>
                </div>
            ),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => (
                <span>
                    {record.callDurationMins || record.callDurationSecs ? (
                        <>
                            <span>
                                {record.callDurationMins +
                                    ':' +
                                    record.callDurationSecs}
                            </span>
                            {record.cloudPhoneCall &&
                            record.cloudPhoneCall.recordingUrl ? (
                                <Button
                                    type="link"
                                    style={{ padding: 0 }}
                                    onClick={() =>
                                        showPlayAudioModal(
                                            record.cloudPhoneCall.recordingUrl
                                        )
                                    }
                                >
                                    <PlayCircleTwoTone
                                        style={{
                                            marginLeft: 5,
                                            fontSize: 14,
                                        }}
                                    />
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Commented By',
            dataIndex: 'commentedBy',
            key: 'commentedBy',
            render: (text, record) => (
                <span>{record.commentedBy.firstName}</span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12, marginRight: 5 }}
                        onClick={() => pinUnpinLeadComment(record)}
                        loading={pinUnpinLoading}
                    >
                        {record.pinned ? 'Unpin' : 'Pin'}
                    </Button>
                    {Utils.isUserAdmin() ? (
                        <>
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => deleteComment(record.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    tyle="link"
                                    style={{
                                        border: 'none',
                                        color: 'red',
                                        fontSize: 9,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </Popconfirm>
                            {record.cloudPhoneCall ? (
                                <Button
                                    type="link"
                                    style={{ padding: 0, marginLeft: 5 }}
                                    onClick={() =>
                                        refreshCloudPhoneCall(record)
                                    }
                                >
                                    {loadingRefreshCloudPhoneCall ? (
                                        <LoadingOutlined
                                            style={{ fontSize: 12 }}
                                        />
                                    ) : (
                                        <ReloadOutlined
                                            style={{ fontSize: 12 }}
                                        />
                                    )}
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                </>
            ),
        },
    ]

    const showPlayAudioModal = (url) => {
        setPlayAudioModalVisible(true)
        setAudioUrl(url)
    }

    const hidePlayAudioModal = () => {
        setPlayAudioModalVisible(false)
    }

    const refreshCloudPhoneCall = (record) => {
        setLoadingRefreshCloudPhoneCall(true)

        var payload = {}
        payload.id = record.cloudPhoneCall.id
        payload.leadType = 'internal'

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.CLOUD_PHONE.REFRESH_CALL_STATUS, postBody)
            .then((response) => {
                message.success(response.data)
                getCartLeadAllComments(selectedRecord)
            })
            .finally(() => {
                setLoadingRefreshCloudPhoneCall(false)
            })
    }

    const pinUnpinLeadComment = (record) => {
        setPinUnpinLoading(true)

        var payload = {}
        payload.id = record.id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        var url = GLOBAL.URL.PIN_CART_LEAD_COMMENT
        if (record.pinned) {
            url = GLOBAL.URL.UNPIN_CART_LEAD_COMMENT
        }

        RestApi.doPost(url, formData)
            .then((res) => {
                getCartLeadAllComments(selectedRecord)
                notification['success']({
                    message: res.data,
                })
            })
            .finally(() => {
                setPinUnpinLoading(false)
            })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const onChangeFilter = (event) => {
        setLeadsCount()

        setFilter(event.target.value)

        if (event.target.value === CLOSED) {
            setOpenOrCloseFilterType(false)
        } else {
            setOpenOrCloseFilterType(true)
        }
    }

    const showUpdateReminderDateModal = (record) => {
        setSelectedRecord(record)
        setEditReminderVisible(true)
        setReminderDate('')
    }

    const hideUpdateReminderDateModal = () => {
        setEditReminderVisible(false)
        setReminderDate('')
    }

    const onChangeReminderDate = (value, dateString) => {
        setReminderDate(dateString)
    }

    const updateReminderDate = () => {
        if (!reminderDate) {
            notification['error']({
                message: 'Select Date!',
            })

            return
        }

        setLoading(true)

        var payload = {}
        payload.userId = selectedRecord.userId
        payload.reminderDate = reminderDate

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SET_CART_LEAD_REMINDER_DATE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                var fullData = data
                fullData.forEach((item) => {
                    if (item.userId === selectedRecord.userId) {
                        item.reminderDate = reminderDate
                        return
                    }
                })

                setData(fullData)
                hideUpdateReminderDateModal()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onDateFilterChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const hideClosingCommentModal = () => {
        setClosingCommentModalVisible(false)
    }

    const getAdminUserOptions = () => {
        var options = []
        adminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    const onChangeAsignee = (value) => {
        setSelectedAsignee(value ? value : '')
    }

    const onChangeFilterLeadType = (value) => {
        setFilterLeadType(value ? value : '')
        setFilterLeadSubType('')
        setFilterSubTypes([])

        RestApi.doGet(
            GLOBAL.URL.LEADS.GET_LEAD_SUB_TYPES_ENABLED +
                'parentType=' +
                value +
                '&parentStatusType=' +
                (openOrCloseFilterType ? 'Open' : 'Closed')
        ).then((res) => {
            setFilterSubTypes(res.data)
        })
    }

    const onChangeFilterLeadSubType = (value) => {
        setFilterLeadSubType(value ? value : '')
    }

    const showAssignToModal = (record) => {
        setSelectedRecord(record)
        setAssignToModalVisible(true)
    }

    const hideAssignToModal = () => {
        setAssignToModalVisible(false)
    }

    const hideOpenModal = () => {
        setOpenModalVisible(false)
    }

    const openStatusTypeOptions = () => {
        var options = []
        openStatusTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.id}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const closedStatusTypeOptions = () => {
        var options = []
        closedStatusTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.id}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const filterLeadSubTypeOptions = () => {
        var options = []
        filterSubTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.id}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const leadSubTypeOptions = () => {
        var options = []
        leadSubTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.type}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    // const showUpdateLeadOpenType = (record) => {
    //     setSelectedRecord(record)
    //     setUpdateLeadOpenTypeVisible(true)
    //     setOpenOrClose(record.open)

    //     setTimeout(() => {
    //         getLeadSubTypes(record.openType)
    //         setTimeout(() => {
    //             form.setFieldsValue({
    //                 type: record.openType,
    //                 subType: record.openSubType,
    //             })
    //         }, 100)
    //     }, 100)
    // }

    const hideUpdateLeadOpenType = () => {
        setUpdateLeadOpenTypeVisible(false)
    }

    const showUpdateModal = (record) => {
        form.resetFields()

        if (leadSources.length === 0) {
            RestApi.doGet(GLOBAL.URL.LEADS.GET_LEAD_SOURCES).then((res) => {
                setLeadSources(res.data)
            })
        }

        setSelectedRecord(record)
        setUpdateModalVisible(true)
        setReminderDate('')
        setOpenOrClose(record.open)

        if (record.stream) {
            handleCourseChange(record.stream)
        } else {
            setSelectedCourse('')
        }

        setTimeout(() => {
            var recordClone = clone(record)
            if (recordClone.open) {
                recordClone.type = recordClone.openType
                recordClone.subType = recordClone.openSubType
            } else {
                recordClone.type = recordClone.closedType
                recordClone.subType = recordClone.closedSubType
            }

            getLeadSubTypes(recordClone.type)
            if (recordClone.reminderDate) {
                setReminderDate(record.reminderDate)
                var reminderDate = dayjs(
                    recordClone.reminderDate,
                    'DD-MMM-YY HH:mm'
                )
                recordClone.reminderDate = reminderDate
            }

            if (recordClone.assignedTo && recordClone.assignedTo.id) {
                recordClone.assignToUserId = recordClone.assignedTo.id
            }

            setSelectedUpdateLeadType(recordClone.type)

            setTimeout(() => {
                form.setFieldsValue({
                    assignToUserId: recordClone.assignToUserId,
                    type: recordClone.type,
                    subType: recordClone.subType,
                    comment: recordClone.comment,
                    reminderDate: recordClone.reminderDate,
                    source: recordClone.source,
                    stream: recordClone.stream,
                    level: recordClone.level,
                })
            }, 100)
        }, 100)
    }

    const handleCourseChange = (value) => {
        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelData(item.levels)
                setSelectedCourse(value)
            }
        })
    }

    const streamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const levelOptions = () => {
        var options = []
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const filterStreamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Option value={item.course} key={item.course}>
                    {item.course}
                </Option>
            )
        })

        return options
    }

    const filterLevelOptions = () => {
        var options = []
        filterLevelData.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const handleFilterCourseChange = (value) => {
        if (!value) {
            setFilterLevelData([])
            setStreamCourseFilter('')
            setStreamLevelFilter('')
        }

        streamData.forEach((item) => {
            if (item.course === value) {
                setFilterLevelData(item.levels)
                setStreamCourseFilter(value)
                setStreamLevelFilter('')
            }
        })
    }

    const hideUpdateModal = () => {
        setUpdateModalVisible(false)
    }

    const openCloseOptions = () => {
        var options = []
        options.push(
            <Radio.Button value={true} key={'open'}>
                Open
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={false} key={'close'}>
                Close
            </Radio.Button>
        )

        return options
    }

    const onOpenCloseChange = (e) => {
        setOpenOrClose(e.target.value)
    }

    const updateLead = () => {
        form.validateFields([
            'type',
            'subType',
            'assignToUserId',
            'comment',
            'source',
            'callDurationMins',
            'callDurationSecs',
            'stream',
            'level',
            'reminderDate',
        ])
            .then((values) => {
                setLoading(true)

                if (!values.subType) {
                    values.subType = ''
                }

                values.userId = selectedRecord.userId
                values.open = openOrClose
                values.reminderDate = reminderDate

                if (!values.stream) {
                    values.stream = ''
                    values.level = ''
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.UPDATE_CART_LEAD, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateModal()

                        RestApi.doGet(
                            GLOBAL.URL.GET_USER_CART + selectedRecord.userId
                        )
                            .then((res) => {
                                var fullData = clone(data)
                                fullData.forEach((item, index) => {
                                    if (item.userId === selectedRecord.userId) {
                                        fullData[index] = res.data
                                        return
                                    }
                                })

                                setData(fullData)
                            })
                            .finally(() => {
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showBulkAssignModal = () => {
        setBulkAssignModalVisible(true)
    }

    const hideBulkAssignModal = () => {
        setBulkAssignModalVisible(false)
    }

    const bulkAssignLeads = () => {
        form.validateFields(['assignToUserId'])
            .then((values) => {
                if (selectedRows.length === 0) {
                    notification['error']({
                        message: 'No leads selected',
                    })
                    return
                }

                setLoading(true)

                var userIds = []
                selectedRows.forEach((item) => {
                    userIds.push(item.userId)
                })

                values.userIds = userIds

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.BULK_ASSIGN_CART_LEAD, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideBulkAssignModal()

                        var adminUser = {}
                        adminUsers.forEach((adminUserRow) => {
                            if (adminUserRow.id === values.assignToUserId) {
                                adminUser = adminUserRow
                            }
                        })

                        var fullData = clone(data)
                        selectedRows.forEach((item) => {
                            fullData.forEach((row) => {
                                if (row.userId === item.userId) {
                                    row.assignedTo = adminUser
                                    return
                                }
                            })
                        })

                        setData(fullData)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const addCallEntry = () => {
        form.validateFields(['callDurationMins', 'callDurationSecs'])
            .then((values) => {
                setLoading(true)

                values.userId = selectedRecord.userId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.ADD_CALL_MADE_ENTRY_TO_CART_LEAD,
                    formData
                )
                    .then((response) => {
                        RestApi.doGet(
                            GLOBAL.URL.GET_CART_LEAD_LATEST_COMMENTS +
                                '?userId=' +
                                selectedRecord.userId
                        )
                            .then((latestComment) => {
                                var fullData = data
                                fullData.forEach((item) => {
                                    if (item.userId === selectedRecord.userId) {
                                        item.latestComment = latestComment.data
                                        return
                                    }
                                })

                                setData(fullData)
                                hideAddCallEntryModal()
                            })
                            .finally(() => {
                                notification['success']({
                                    message: response.data,
                                })
                                setLoading(false)
                            })
                    })
                    .catch(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const leadSourceOptions = () => {
        var options = []
        leadSources.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const getLeadSubTypes = (parentType) => {
        setLeadSubTypes([])

        RestApi.doGet(
            GLOBAL.URL.LEADS.GET_LEAD_SUB_TYPES_ENABLED +
                'parentType=' +
                parentType +
                '&parentStatusType=' +
                (openOrClose ? 'Open' : 'Closed')
        ).then((res) => {
            setLeadSubTypes(res.data)
        })
    }

    const onChangeLeadType = (e) => {
        setSelectedUpdateLeadType(e.target.value)
        getLeadSubTypes(e.target.value)
        form.setFieldsValue({ subType: '' })
    }

    const showAddCallEntryModal = (record) => {
        setSelectedRecord(record)
        setAddCallEntryModalVisible(true)
    }

    const hideAddCallEntryModal = () => {
        setAddCallEntryModalVisible(false)
    }

    const getAttemptOptions = () => {
        var options = []
        attempts.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const onChangeAttemptFilter = (value) => {
        setSelectedAttempt(value ? value : '')
    }

    const onChangeFollowUpLeadType = (event) => {
        setFollowUpLeadType(event.target.value)
    }

    const showHighProspectModal = (record) => {
        setSelectedRecord(record)
        setHighProspectModalVisible(true)
        setExpectedSaleDate('')

        if (record.highProspectData && record.highProspectData.uid) {
            if (record.highProspectData.expectedSaleDate) {
                setExpectedSaleDate(record.highProspectData.expectedSaleDate)
                var expectedSaleDateMoment = dayjs(
                    record.highProspectData.expectedSaleDate,
                    'DD-MMM-YYYY'
                )
            }

            setTimeout(() => {
                form.setFieldsValue({
                    active: record.highProspectData.active,
                    expectedSale: record.highProspectData.expectedSale,
                    expectedSaleDate: expectedSaleDateMoment,
                })
            }, 100)
        } else {
            setTimeout(() => {
                form.setFieldsValue({
                    active: true,
                })
            }, 100)
        }
    }

    const hideHighProspectModal = () => {
        setSelectedRecord({})
        setHighProspectModalVisible(false)
    }

    const onChangeExpectedSaleDate = (value, dateString) => {
        setExpectedSaleDate(dateString ? dateString : '')
    }

    const updateHighProspectLead = () => {
        form.validateFields(['active', 'expectedSale', 'expectedSaleDate'])
            .then((values) => {
                setLoading(true)

                var payload = values
                payload.expectedSaleDate = expectedSaleDate

                if (
                    selectedRecord.highProspectData &&
                    selectedRecord.highProspectData.uid
                ) {
                    payload.uid = selectedRecord.highProspectData.uid
                } else {
                    payload.userId = selectedRecord.userId
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(
                    GLOBAL.URL.LEADS.UPDATE_HIGH_PROSPECT_LEAD,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        RestApi.doGet(
                            GLOBAL.URL.GET_CART_LEAD + selectedRecord.userId
                        )
                            .then((res) => {
                                var fullData = clone(data)
                                fullData.forEach((item, index) => {
                                    if (item.userId === selectedRecord.userId) {
                                        fullData[index] = res.data
                                        return
                                    }
                                })

                                setData(fullData)
                                hideHighProspectModal()
                            })
                            .finally(() => {
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const onChangeHighProspectFilter = (event) => {
        setHighProspectsFilter(event.target.value)
    }

    const onUpdateLeadType = (value) => {
        const queryParams = new URLSearchParams(location.search)

        if (value) {
            queryParams.set('leadType', value)
        } else {
            queryParams.delete('leadType')
        }

        navigate({
            pathname: location.pathname,
            search: queryParams.toString(),
        })
    }

    const onUpdateHasLeadType = (value) => {
        setFilterHasLeadTypeEntry(value ? value : '')
        const queryParams = new URLSearchParams(location.search)

        if (value) {
            queryParams.set('hasLeadTypeEntry', value)
        } else {
            queryParams.delete('hasLeadTypeEntry')
        }

        navigate({
            pathname: location.pathname,
            search: queryParams.toString(),
        })
    }

    return (
        <div>
            <div>
                <div>
                    <Radio.Group onChange={onChangeFilter} value={filter}>
                        <Radio.Button value={OPEN} key={OPEN}>
                            {'Open' +
                                (filter === OPEN && leadsCount
                                    ? ' (' + leadsCount + ')'
                                    : '')}
                        </Radio.Button>
                        <Radio.Button value={UNASSIGNED} key={UNASSIGNED}>
                            {'Unassigned' +
                                (filter === UNASSIGNED && leadsCount
                                    ? ' (' + leadsCount + ')'
                                    : '')}
                        </Radio.Button>
                        <Radio.Button value={FOLLOW_UP} key={FOLLOW_UP}>
                            {'Follow Up' +
                                (filter === FOLLOW_UP && leadsCount
                                    ? ' (' + leadsCount + ')'
                                    : '')}
                        </Radio.Button>
                        <Radio.Button value={CLOSED} key={CLOSED}>
                            {'Closed' +
                                (filter === CLOSED && leadsCount
                                    ? ' (' + leadsCount + ')'
                                    : '')}
                        </Radio.Button>
                    </Radio.Group>
                    <Button type="link" onClick={fetchData}>
                        {loading ? (
                            <LoadingOutlined style={{ fontSize: 15 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 15 }} />
                        )}
                    </Button>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Select
                        placeholder="Lead Type"
                        showSearch
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 200 }}
                        onChange={onUpdateLeadType}
                        value={filterLeadType || undefined}
                    >
                        {openOrCloseFilterType
                            ? openStatusTypeOptions()
                            : closedStatusTypeOptions()}
                    </Select>
                    {filterSubTypes && filterSubTypes.length ? (
                        <Select
                            placeholder="Lead Sub Type"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 200, marginLeft: 10 }}
                            onChange={onChangeFilterLeadSubType}
                            value={filterLeadSubType || undefined}
                        >
                            {filterLeadSubTypeOptions()}
                        </Select>
                    ) : null}
                    <Select
                        placeholder="Filter By Asignee"
                        showSearch
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 200, marginLeft: 10 }}
                        onChange={onChangeAsignee}
                        value={selectedAsignee || undefined}
                    >
                        {getAdminUserOptions()}
                    </Select>
                    <RangePicker
                        format="DD-MMM-YY"
                        placeholder={['Start Date', 'End Date']}
                        onChange={onDateFilterChange}
                        value={
                            startDate && endDate
                                ? [dayjs(startDate), dayjs(endDate)]
                                : null
                        }
                        style={{ marginLeft: 10, width: 250 }}
                    />
                    <span style={{ marginLeft: 10 }}>
                        {'Cart Range: '}
                        <InputNumber
                            min={0}
                            max={99999}
                            defaultValue={minCartValue}
                            step={1000}
                            onChange={(value) => setMinCartValue(value)}
                        />
                        <span style={{ marginLeft: 10 }}>{' - '}</span>
                        <InputNumber
                            min={0}
                            max={99999}
                            defaultValue={maxCartValue}
                            step={1000}
                            style={{ marginLeft: 10 }}
                            onChange={(value) => setMaxCartValue(value)}
                        />
                    </span>
                </div>
                <div style={{ marginTop: 5 }}>
                    <span>
                        <Input
                            style={{ width: 200 }}
                            placeholder="Phone Ending"
                            onChange={(e) => setPhoneEndingWith(e.target.value)}
                            onPressEnter={fetchData}
                        />
                    </span>
                    <span style={{ marginLeft: 10 }}>
                        <Input
                            style={{ width: 200 }}
                            placeholder="Email Starting"
                            onChange={(e) =>
                                setEmailStartingWith(e.target.value)
                            }
                            onPressEnter={fetchData}
                        />
                    </span>
                    <Select
                        placeholder="Stream Course"
                        onChange={(value) => handleFilterCourseChange(value)}
                        showSearch
                        style={{ width: 100, marginLeft: 10 }}
                        allowClear={true}
                        value={streamCourseFilter || undefined}
                    >
                        {filterStreamOptions()}
                    </Select>
                    {streamCourseFilter ? (
                        <Select
                            placeholder="Stream level"
                            showSearch
                            style={{ width: 120, marginLeft: 10 }}
                            value={streamLevelFilter || undefined}
                            onChange={(value) =>
                                setStreamLevelFilter(value ? value : '')
                            }
                            allowClear={true}
                        >
                            {filterLevelOptions()}
                        </Select>
                    ) : null}
                    <span style={{ marginLeft: 10 }}>
                        <Select
                            placeholder="Attempt"
                            showSearch
                            style={{ width: 100 }}
                            allowClear={true}
                            onChange={onChangeAttemptFilter}
                            value={selectedAttempt || undefined}
                        >
                            {getAttemptOptions()}
                        </Select>
                    </span>
                    <span style={{ marginLeft: 10 }}>
                        <span>{'High Prospect: '}</span>
                        <Radio.Group
                            onChange={onChangeHighProspectFilter}
                            value={highProspectsFilter}
                        >
                            <Radio.Button value={'all'} key={'all'}>
                                All
                            </Radio.Button>
                            <Radio.Button value={'active'} key={'active'}>
                                Active
                            </Radio.Button>
                            <Radio.Button value={'inactive'} key={'inactive'}>
                                Closed
                            </Radio.Button>
                        </Radio.Group>
                    </span>
                </div>
                <div style={{ marginTop: 5 }}>
                    <Select
                        placeholder="Has Lead Type Entry"
                        showSearch
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 200 }}
                        onChange={onUpdateHasLeadType}
                        value={filterHasLeadTypeEntry || undefined}
                    >
                        {openOrCloseFilterType
                            ? openStatusTypeOptions()
                            : closedStatusTypeOptions()}
                    </Select>
                    <Button
                        type="default"
                        disabled={selectedRows.length === 0}
                        onClick={showBulkAssignModal}
                        size="small"
                        style={{ marginLeft: 10 }}
                    >
                        Bulk Assign
                    </Button>
                    {filter === FOLLOW_UP ? (
                        <Radio.Group
                            onChange={onChangeFollowUpLeadType}
                            defaultValue={followUpLeadType}
                            style={{ marginLeft: 10 }}
                        >
                            <Radio.Button value={'open'}>Open</Radio.Button>
                            <Radio.Button value={'closed'}>Closed</Radio.Button>
                        </Radio.Group>
                    ) : null}
                    {/* <span style={{ marginLeft: 10 }}>
                        <span>Action Leads</span>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={includeActionLeads}
                            onClick={(enabled) =>
                                setIncludeActionLeads(enabled)
                            }
                            disabled={loading}
                            style={{ marginLeft: 10 }}
                        />
                    </span> */}
                </div>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="userId"
                style={{ marginTop: 10 }}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageData}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    More Data
                </Button>
            ) : null}
            <Modal
                title={'Add Comment'}
                open={addCommentModalVisible}
                onOk={() => {
                    addComment()
                }}
                confirmLoading={loading}
                onCancel={closeAddCommentModal}
                okText={'Add Comment'}
                destroyOnClose={true}
                width={800}
            >
                <span>
                    Student: <strong>{selectedRecord.name}</strong>
                </span>
                <Form
                    layout="horizontal"
                    form={form}
                    {...formItemLayout}
                    style={{ marginTop: 20 }}
                >
                    <Form.Item
                        label="Comments"
                        name="comments"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <TextArea
                            placeholder="Comments"
                            autoFocus
                            ref={inputRef}
                            onPaste={(e) =>
                                processClipboardAttachment(
                                    e,
                                    setCommentAttachmentFileList
                                )
                            }
                        />
                    </Form.Item>
                </Form>
                <ILAttachmentFileList
                    attachmentFileList={commentAttachmentFileList}
                    setAttachmentFileList={setCommentAttachmentFileList}
                />
            </Modal>
            <Modal
                title={'Comments'}
                open={allCommentsModalVisible}
                onOk={() => {
                    addComment()
                }}
                onCancel={hideAllCommentsModal}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                <span>
                    Student: <strong>{selectedRecord.name}</strong>
                </span>

                {editCommentVisible ? (
                    <div style={{ marginTop: 10 }}>
                        <h4 style={{ textDecoration: 'underline' }}>
                            Update Comment
                        </h4>
                        <Form
                            layout="horizontal"
                            {...formItemLayout}
                            form={form}
                            initialValues={{
                                comments: selectedComment.comments,
                            }}
                            style={{ marginTop: 20 }}
                        >
                            <Form.Item
                                label="Comments"
                                name="comments"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Needed!',
                                    },
                                ]}
                                valuePropName="value"
                            >
                                <TextArea placeholder="Comments" autoFocus />
                            </Form.Item>
                        </Form>
                        <Button
                            type="primary"
                            size="small"
                            loading={loading}
                            onClick={updateComments}
                        >
                            Update
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            loading={loading}
                            onClick={() => setEditCommentVisible(false)}
                            style={{ marginLeft: 10 }}
                        >
                            Cancel
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Button
                            type="primary"
                            style={{ marginTop: 10 }}
                            onClick={() => {
                                addCommentFromAllCommentsModal()
                            }}
                        >
                            Add Comment
                        </Button>
                        {allCommentsPinned.length ? (
                            <Radio.Group
                                value={selectedCommentFilter}
                                onChange={(e) =>
                                    setSelectedCommentFilter(e.target.value)
                                }
                                style={{ marginLeft: 10 }}
                            >
                                <Radio.Button
                                    value={COMMENT_FILTER_ALL}
                                    key={COMMENT_FILTER_ALL}
                                >
                                    All
                                </Radio.Button>
                                <Radio.Button
                                    value={COMMENT_FILTER_PINNED}
                                    key={COMMENT_FILTER_PINNED}
                                >
                                    {'Pinned (' +
                                        allCommentsPinned.length +
                                        ')'}
                                </Radio.Button>
                            </Radio.Group>
                        ) : null}
                        <Button
                            type="link"
                            onClick={() =>
                                getCartLeadAllComments(selectedRecord)
                            }
                        >
                            {loadingComments ? (
                                <LoadingOutlined style={{ fontSize: 20 }} />
                            ) : (
                                <ReloadOutlined style={{ fontSize: 20 }} />
                            )}
                        </Button>
                    </div>
                )}
                <Table
                    columns={allCommentsColumns}
                    dataSource={
                        selectedCommentFilter === COMMENT_FILTER_ALL
                            ? allComments
                            : allCommentsPinned
                    }
                    pagination={false}
                    size="small"
                    style={{ marginTop: 10 }}
                    rowKey="id"
                    loading={loadingComments}
                />
            </Modal>
            <Modal
                title={'Set Follow Up Date'}
                open={editReminderVisible}
                onOk={() => {
                    updateReminderDate()
                }}
                confirmLoading={loading}
                onCancel={hideUpdateReminderDateModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                <div>
                    Student: <strong>{selectedRecord.name}</strong>
                    {selectedRecord.reminderDate ? (
                        <div style={{ marginTop: 10 }}>
                            Current follow up:{' '}
                            <strong>{selectedRecord.reminderDate}</strong>
                        </div>
                    ) : null}
                </div>
                <div style={{ marginTop: 20 }}>
                    <span style={{ marginRight: 10 }}>Select date: </span>
                    <DatePicker
                        showTime
                        placeholder="Select Date"
                        format="DD-MMM-YY HH:mm"
                        onChange={onChangeReminderDate}
                    />
                </div>
            </Modal>
            <Modal
                title={'Closing Lead'}
                open={closingCommentModalVisible}
                onOk={() => {
                    closeLead()
                }}
                confirmLoading={loading}
                onCancel={hideClosingCommentModal}
                okText={'Close'}
                destroyOnClose={true}
                width={800}
            >
                <div>
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Lead Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Radio.Group>
                                {closedStatusTypeOptions()}
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="Comments"
                            name="comments"
                            valuePropName="value"
                            rules={[
                                {
                                    required: false,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <TextArea placeholder="Comments" autoFocus />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <Modal
                title={'Open Lead'}
                open={openModalVisible}
                onOk={() => {
                    openLead()
                }}
                confirmLoading={loading}
                onCancel={hideOpenModal}
                okText={'Open'}
                destroyOnClose={true}
                width={800}
            >
                <div>
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Lead Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Radio.Group>{openStatusTypeOptions()}</Radio.Group>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <Modal
                title={'Update Lead Type'}
                open={updateLeadOpenTypeVisible}
                onOk={() => {
                    updateLeadOpenType()
                }}
                confirmLoading={loading}
                onCancel={hideUpdateLeadOpenType}
                okText={'Update'}
                destroyOnClose={true}
                width={800}
            >
                <div>
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Lead Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Radio.Group>{openStatusTypeOptions()}</Radio.Group>
                        </Form.Item>
                        {leadSubTypes.length > 0 ? (
                            <Form.Item
                                label="Lead Sub Type"
                                name="subType"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    {leadSubTypeOptions()}
                                </Radio.Group>
                            </Form.Item>
                        ) : null}
                    </Form>
                </div>
            </Modal>
            <Modal
                title={'Assign Lead'}
                open={assignToModalVisible}
                onOk={() => {
                    assignLead()
                }}
                confirmLoading={loading}
                onCancel={hideAssignToModal}
                okText={'Assign'}
                destroyOnClose={true}
            >
                {selectedRecord.assignedTo ? (
                    <div style={{ marginBottom: 10 }}>
                        Current assignee:{' '}
                        <strong>{selectedRecord.assignedTo.firstName}</strong>
                    </div>
                ) : null}
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Assign To"
                        name="assignToUserId"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Asignee"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAdminUserOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Update Lead'}
                open={updateModalVisible}
                onOk={() => {
                    updateLead()
                }}
                confirmLoading={loading}
                onCancel={hideUpdateModal}
                okText={'Update'}
                destroyOnClose={true}
                width={1000}
            >
                <div style={{ marginLeft: 113, marginBottom: 24 }}>
                    <span>Lead Status: </span>
                    <Radio.Group
                        onChange={onOpenCloseChange}
                        value={openOrClose}
                    >
                        {openCloseOptions()}
                    </Radio.Group>
                </div>
                <Form
                    form={form}
                    initialValues={{ callDurationMins: 0, callDurationSecs: 0 }}
                    layout="horizontal"
                    {...formItemLayout}
                >
                    <Form.Item
                        label="Lead Type"
                        style={{ marginBottom: 5 }}
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group onChange={onChangeLeadType}>
                            {openOrClose
                                ? openStatusTypeOptions()
                                : closedStatusTypeOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {leadSubTypes.length > 0 ? (
                        <Form.Item
                            label="Lead Sub Type"
                            style={{ marginBottom: 5 }}
                            name="subType"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Radio.Group>{leadSubTypeOptions()}</Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="Assign To"
                        style={{ marginBottom: 5 }}
                        name="assignToUserId"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Asignee"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAdminUserOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Reminder"
                        style={{ marginBottom: 5 }}
                        name="reminderDate"
                        rules={[
                            {
                                required:
                                    selectedUpdateLeadType ===
                                    LEAD_TYPE_FUTURE_PROSPECT,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="Select Date"
                            format="DD-MMM-YY HH:mm"
                            showTime
                            onChange={onChangeReminderDate}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Source"
                        style={{ marginBottom: 5 }}
                        name="source"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group>{leadSourceOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Course Stream"
                        style={{ marginBottom: 0 }}
                        name="stream"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group
                            onChange={(e) => handleCourseChange(e.target.value)}
                        >
                            {streamOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {selectedCourse ? (
                        <Form.Item
                            label="Course Level"
                            style={{ marginBottom: 0 }}
                            name="level"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Radio.Group placeholder="Select Level">
                                {levelOptions()}
                            </Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="Call Mins"
                        style={{ marginBottom: 5 }}
                        name="callDurationMins"
                        valuePropName="value"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Call Secs"
                        style={{ marginBottom: 5 }}
                        name="callDurationSecs"
                        valuePropName="value"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={59} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Comment"
                        style={{ marginBottom: 5 }}
                        name="comment"
                        valuePropName="value"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea placeholder="Comment" />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Bulk Assign'}
                open={bulkAssignModalVisible}
                onOk={() => {
                    bulkAssignLeads()
                }}
                confirmLoading={loading}
                onCancel={hideBulkAssignModal}
                okText={'Assign'}
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Assign To"
                        name="assignToUserId"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Asignee"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAdminUserOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Add Call Entry'}
                open={addCallEntryModalVisible}
                onOk={() => {
                    addCallEntry()
                }}
                confirmLoading={loading}
                onCancel={hideAddCallEntryModal}
                okText={'Add'}
                destroyOnClose={true}
            >
                <h4>{selectedRecord.name}</h4>
                <Form
                    form={form}
                    initialValues={{ callDurationMins: 0, callDurationSecs: 0 }}
                    layout="horizontal"
                    {...formItemLayout}
                >
                    <Form.Item
                        label="Call Mins"
                        style={{ marginBottom: 5 }}
                        name="callDurationMins"
                        valuePropName="value"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Call Secs"
                        style={{ marginBottom: 5 }}
                        name="callDurationSecs"
                        valuePropName="value"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'High Prospect Lead'}
                open={highProspectModalVisible}
                onOk={updateHighProspectLead}
                confirmLoading={loading}
                onCancel={hideHighProspectModal}
                okText={'Update'}
                destroyOnClose={true}
                width={800}
            >
                {highProspectModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Active"
                            style={{ marginBottom: 10 }}
                            name="active"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio.Button value={true} key={'Yes'}>
                                    {'Yes'}
                                </Radio.Button>
                                <Radio.Button value={false} key={'No'}>
                                    {'No'}
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="Expected Sale"
                            style={{ marginBottom: 10 }}
                            name="expectedSale"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber autoFocus min={0} />
                        </Form.Item>
                        <Form.Item
                            label="Expected Sale Date"
                            style={{ marginBottom: 10 }}
                            name="expectedSaleDate"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <DatePicker
                                placeholder="Select Date"
                                format="DD-MMM-YYYY"
                                onChange={onChangeExpectedSaleDate}
                            />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                open={playAudioModalVisible}
                title={'Phone Call Recording'}
                onCancel={hidePlayAudioModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <ReactAudioPlayer src={audioUrl} autoPlay controls />
            </Modal>
        </div>
    )
}

export default CartLeads
