import React, { useState, useEffect } from 'react'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'
import {
    Descriptions,
    Radio,
    Table,
    Tag,
    Button,
    Popconfirm,
    message,
    Modal,
    InputNumber,
    Input,
    DatePicker,
    Select,
    Form,
    notification,
} from 'antd'
import { EditTwoTone, LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import clone from 'clone'
import SendWhatsAppToLead from '../../Sales/SendWhatsAppToLead'
import UserAcquisitionInfo from '../../Leads/UserAcquisitionInfo'
import { Utils } from '../../JS/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faThumbtack } from '@fortawesome/free-solid-svg-icons'
import { PlayCircleTwoTone } from '@ant-design/icons'
import UserCartView from './UserCartView'
import ReactAudioPlayer from 'react-audio-player'
import dayjs from 'dayjs'
import Linkify from 'linkify-react'
import ILAttachmentFileList, {
    processClipboardAttachment,
} from '../../ILShareComponents/ILAttachmentFileList'

const COMMENT_FILTER_ALL = 'COMMENT_FILTER_ALL'
const COMMENT_FILTER_PINNED = 'COMMENT_FILTER_PINNED'
const LEAD_TYPE_FUTURE_PROSPECT = 'Future Prospect'

function UserCart(props) {
    const { TextArea } = Input
    const { Option } = Select
    const [form] = Form.useForm()

    const userId = props.userId
    const userData = props.userData

    const [loadingCart, setLoadingCart] = useState(false)
    const [cart, setCart] = useState({})
    const [loadingCartComments, setLoadingCartComments] = useState(false)
    const [cartComments, setCartComments] = useState([])
    const [cartCommentsPinned, setCartCommentsPinned] = useState([])
    const [leadExternalComments, setLeadExternalComments] = useState([])
    const [leadExternalCommentsPinned, setLeadExternalCommentsPinned] =
        useState([])
    const [leadExternalCourses, setLeadExternalCourses] = useState([])
    const [selectedCommentFilter, setSelectedCommentFilter] =
        useState(COMMENT_FILTER_ALL)
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [selectedCourse, setSelectedCourse] = useState('')
    const [openStatusTypes, setOpenStatusTypes] = useState([])
    const [closedStatusTypes, setClosedStatusTypes] = useState([])
    const [adminUsers, setAdminUsers] = useState([])
    const [leadSources, setLeadSources] = useState([])
    const [updateCartModalVisible, setUpdateCartModalVisible] = useState(false)
    const [reminderDate, setReminderDate] = useState('')
    const [openOrClose, setOpenOrClose] = useState(true)
    const [leadSubTypes, setLeadSubTypes] = useState([])
    const [callUserLoading, setCallUserLoading] = useState(false)
    const [addCallEntryModalVisible, setAddCallEntryModalVisible] =
        useState(false)
    const [pinUnpinLoading, setPinUnpinLoading] = useState(false)
    const [loadingRefreshCloudPhoneCall, setLoadingRefreshCloudPhoneCall] =
        useState(false)
    const [addCartCommentModalVisible, setAddCartCommentModalVisible] =
        useState(false)
    const [userCartViewVisible, setUserCartViewVisible] = useState(false)
    const [highProspectModalVisible, setHighProspectModalVisible] =
        useState(false)
    const [expectedSaleDate, setExpectedSaleDate] = useState('')
    const [editReminderVisible, setEditReminderVisible] = useState(false)
    const [assignToModalVisible, setAssignToModalVisible] = useState(false)
    const [updateLeadOpenTypeVisible, setUpdateLeadOpenTypeVisible] =
        useState(false)
    const [loading, setLoading] = useState(false)
    const [openModalVisible, setOpenModalVisible] = useState(false)
    const [closingCommentModalVisible, setClosingCommentModalVisible] =
        useState(false)
    const [pinUnpinLeadExternalLoading, setPinUnpinLeadExternalLoading] =
        useState(false)
    const [selectedCartComment, setSelectedCartComment] = useState({})
    const [
        updateCartCommentDateModalVisible,
        setUpdateCartCommentDateModalVisible,
    ] = useState(false)
    const [updatedCartCommentDate, setUpdatedCartCommentDate] = useState(null)
    const [
        updateCartCommentDurationModalVisible,
        setUpdateCartCommentDurationModalVisible,
    ] = useState(false)
    const [playAudioModalVisible, setPlayAudioModalVisible] = useState(false)
    const [audioUrl, setAudioUrl] = useState('')
    const [commentAttachmentFileList, setCommentAttachmentFileList] = useState(
        []
    )
    const [selectedLeadType, setSelectedLeadType] = useState()

    useEffect(() => {
        getCart()
        getStreamData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCart = () => {
        setLoadingCart(true)

        RestApi.doGet(GLOBAL.URL.GET_USER_CART + userId)
            .then((res) => {
                setCart(res.data)
                getCartComments(res.data)
            })
            .catch((error) => {
                setLoadingCart(false)
            })
    }

    const getStreamData = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
            setStreamData(JSON.parse(res.data))
        })
    }

    const getCartComments = (cartData = cart) => {
        setLoadingCartComments(true)

        var leadExternalCommentsPinned1 = []
        var cartCommentsPinned1 = []

        RestApi.doGet(
            GLOBAL.URL.GET_CART_LEAD_ALL_COMMENTS + '?userId=' + userId
        )
            .then((response) => {
                response.data.forEach((comment) => {
                    if (comment.pinned) {
                        cartCommentsPinned1.push(comment)
                    }
                })

                setCartComments(response.data)
                setCartCommentsPinned(cartCommentsPinned1)

                // External comments
                if (cartData.leadExternal && cartData.leadExternal.leadId) {
                    RestApi.doGet(
                        GLOBAL.URL.LEADS.GET_LEAD_EXTERNAL_COMMENTS +
                            cartData.leadExternal.leadId
                    ).then((res) => {
                        res.data.forEach((comment) => {
                            if (comment.pinned) {
                                leadExternalCommentsPinned1.push(comment)
                            }
                        })

                        setLeadExternalComments(res.data)
                        setLeadExternalCommentsPinned(
                            leadExternalCommentsPinned1
                        )

                        RestApi.doGet(
                            GLOBAL.URL.LEADS.GET_LEAD_EXTERNAL_COURSES +
                                cartData.leadExternal.leadId
                        ).then((response) => {
                            setLeadExternalCourses(response.data)

                            if (
                                !(
                                    cartCommentsPinned.length +
                                    leadExternalCommentsPinned.length
                                )
                            ) {
                                setSelectedCommentFilter(COMMENT_FILTER_ALL)
                            }
                        })
                    })
                } else {
                    if (
                        !(
                            cartCommentsPinned.length +
                            leadExternalCommentsPinned.length
                        )
                    ) {
                        setSelectedCommentFilter(COMMENT_FILTER_ALL)
                    }
                }
            })
            .finally(() => {
                setLoadingCart(false)
                setLoadingCartComments(false)
            })
    }

    const handleCourseChange = (value) => {
        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelData(item.levels)
                setSelectedCourse(value)
            }
        })
    }

    const getLeadSubTypes = (parentType) => {
        setLeadSubTypes([])

        RestApi.doGet(
            GLOBAL.URL.LEADS.GET_LEAD_SUB_TYPES_ENABLED +
                'parentType=' +
                parentType +
                '&parentStatusType=' +
                (openOrClose ? 'Open' : 'Closed')
        ).then((res) => {
            setLeadSubTypes(res.data)
            form.setFieldsValue({
                subType: '',
            })
        })
    }

    const showUpdateCartModal = () => {
        form.resetFields()

        if (cart.stream) {
            handleCourseChange(cart.stream)
        } else {
            setSelectedCourse('')
        }

        if (openStatusTypes.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_OPEN_STATUS_TYPES).then(
                (res) => {
                    setOpenStatusTypes(res.data)
                }
            )
        }

        if (closedStatusTypes.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_CLOSED_STATUS_TYPES).then(
                (res) => {
                    setClosedStatusTypes(res.data)
                }
            )
        }

        if (adminUsers.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_ADMIN_USERS).then((res) => {
                setAdminUsers(res.data)
            })
        }

        if (leadSources.length === 0) {
            RestApi.doGet(GLOBAL.URL.LEADS.GET_LEAD_SOURCES).then((res) => {
                setLeadSources(res.data)
            })
        }

        var recordClone = clone(cart)

        setUpdateCartModalVisible(true)
        setReminderDate('')
        setOpenOrClose(recordClone.open)

        setTimeout(() => {
            if (recordClone.open) {
                recordClone.type = recordClone.openType
                recordClone.subType = recordClone.openSubType
            } else {
                recordClone.type = recordClone.closedType
                recordClone.subType = recordClone.closedSubType
            }

            getLeadSubTypes(recordClone.type)

            if (recordClone.reminderDate) {
                setReminderDate(cart.reminderDate)

                var reminderDate = dayjs(
                    recordClone.reminderDate,
                    'DD-MMM-YY HH:mm'
                )
                recordClone.reminderDate = reminderDate
            }

            if (recordClone.assignedTo && recordClone.assignedTo.id) {
                recordClone.assignToUserId = recordClone.assignedTo.id
            }

            setSelectedLeadType(recordClone.type)

            setTimeout(() => {
                form.setFieldsValue({
                    assignToUserId: recordClone.assignToUserId,
                    type: recordClone.type,
                    subType: recordClone.subType,
                    comment: recordClone.comment,
                    reminderDate: recordClone.reminderDate,
                    source: recordClone.source ? recordClone.source : '',
                    stream: recordClone.stream,
                    level: recordClone.level,
                })
            }, 100)
        }, 100)
    }

    const cartItemsTableColumns = [
        {
            title: '#',
            dataIndex: 'cnt',
            key: 'cnt',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: 100,
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
            width: 100,
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
    ]

    const cartCommentsColumns = [
        {
            title: '#',
            dataIndex: 'cnt',
            key: 'cnt',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 140,
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.date}
                    {Utils.isUserAdmin() ? (
                        <Button
                            type="link"
                            onClick={() =>
                                showUpdateCartCommentDateModal(record)
                            }
                            style={{ padding: 0 }}
                        >
                            <EditTwoTone style={{ fontSize: 12 }} />
                        </Button>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (text, record) => (
                <>
                    {record.pinned ? (
                        <FontAwesomeIcon
                            icon={faThumbtack}
                            style={{ color: '#1890ff', marginRight: 10 }}
                        />
                    ) : null}
                    <Linkify
                        as="p"
                        options={{
                            target: '_blank',
                            rel: 'noopener noreferrer',
                        }}
                        style={{
                            marginBlockStart: '0.4em',
                            marginBlockEnd: '0.4em',
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {record.comments}
                    </Linkify>

                    <p
                        style={{
                            whiteSpace: 'pre-wrap',
                        }}
                    >
                        {record.cloudPhoneCall
                            ? '  ~  ' +
                              record.cloudPhoneCall.direction +
                              '  ~  ' +
                              record.cloudPhoneCall.status +
                              (record.cloudPhoneCall.leg1Status
                                  ? '  ~  ' +
                                    record.cloudPhoneCall.leg1Status +
                                    ' / ' +
                                    (record.cloudPhoneCall.leg2Status
                                        ? record.cloudPhoneCall.leg2Status
                                        : '')
                                  : '')
                            : ''}
                    </p>
                </>
            ),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => (
                <span>
                    {Utils.isUserAdmin() ? (
                        <Button
                            type="link"
                            onClick={() => {
                                showUpdateCartCommentDurationModal(record)
                            }}
                            style={{ padding: 0 }}
                        >
                            <EditTwoTone style={{ fontSize: 12 }} />
                        </Button>
                    ) : null}
                    {record.callDurationMins || record.callDurationSecs ? (
                        <>
                            <span>
                                {record.callDurationMins +
                                    ':' +
                                    record.callDurationSecs}
                            </span>
                            {record.cloudPhoneCall &&
                            record.cloudPhoneCall.recordingUrl ? (
                                <Button
                                    type="link"
                                    style={{ padding: 0 }}
                                    onClick={() =>
                                        showPlayAudioModal(
                                            record.cloudPhoneCall.recordingUrl
                                        )
                                    }
                                >
                                    <PlayCircleTwoTone
                                        style={{
                                            marginLeft: 5,
                                            fontSize: 14,
                                        }}
                                    />
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Commented By',
            dataIndex: 'commentedBy',
            key: 'commentedBy',
            render: (text, record) => (
                <span>{record.commentedBy.firstName}</span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() => pinUnpinLeadComment(record)}
                        loading={pinUnpinLoading}
                    >
                        {record.pinned ? 'Unpin' : 'Pin'}
                    </Button>
                    {Utils.isUserAdmin() ? (
                        <>
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => deleteLeadComment(record.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    type="link"
                                    style={{
                                        border: 'none',
                                        color: 'red',
                                        fontSize: 9,
                                        marginLeft: 5,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </Popconfirm>
                            {record.cloudPhoneCall ? (
                                <Button
                                    type="link"
                                    style={{ padding: 0, marginLeft: 5 }}
                                    onClick={() =>
                                        refreshCloudPhoneCall(record)
                                    }
                                >
                                    {loadingRefreshCloudPhoneCall ? (
                                        <LoadingOutlined
                                            style={{ fontSize: 12 }}
                                        />
                                    ) : (
                                        <ReloadOutlined
                                            style={{ fontSize: 12 }}
                                        />
                                    )}
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                </>
            ),
        },
    ]

    const leadExternalCommentsColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 140,
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.createdAt}</span>
            ),
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (text, record) => (
                <span>
                    {record.pinned ? (
                        <FontAwesomeIcon
                            icon={faThumbtack}
                            style={{ color: '#1890ff', marginRight: 10 }}
                        />
                    ) : null}
                    <span>{record.comments}</span>
                </span>
            ),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => (
                <span>
                    {record.callDurationMins + ':' + record.callDurationSecs}
                    {record.cloudPhoneCall &&
                    record.cloudPhoneCall.recordingUrl ? (
                        <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={() =>
                                showPlayAudioModal(
                                    record.cloudPhoneCall.recordingUrl
                                )
                            }
                        >
                            <PlayCircleTwoTone
                                style={{
                                    marginLeft: 5,
                                    fontSize: 14,
                                }}
                            />
                        </Button>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Commented By',
            dataIndex: 'commentedBy',
            key: 'commentedBy',
            render: (text, record) => (
                <span>{record.commentedBy.firstName}</span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() => pinUnpinLeadExternalComment(record)}
                        loading={pinUnpinLeadExternalLoading}
                    >
                        {record.pinned ? 'Unpin' : 'Pin'}
                    </Button>
                    {Utils.isUserAdmin() ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() =>
                                deleteLeadExternalComment(record.uid)
                            }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                tyle="link"
                                style={{
                                    border: 'none',
                                    color: 'red',
                                    fontSize: 9,
                                    marginLeft: 10,
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
    ]

    const leadExternalCourseColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => (
                <span style={{ fontSize: 11 }}>{record.createdAt}</span>
            ),
        },
    ]

    const callLead = () => {
        setCallUserLoading(true)

        var payload = {}
        payload.userId = userId

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.CLOUD_PHONE.CALL_INTERNAL_LEAD, postBody)
            .then((response) => {
                message.success(response.data)
                getCartComments()
            })
            .finally(() => {
                setCallUserLoading(false)
            })
    }

    const showAddCallEntryModal = () => {
        setAddCallEntryModalVisible(true)
    }

    const hideAddCallEntryModal = () => {
        setAddCallEntryModalVisible(false)
    }

    const showAddCartCommentModal = () => {
        setAddCartCommentModalVisible(true)
        form.resetFields()
    }

    const hideAddCartCommentModal = () => {
        setAddCartCommentModalVisible(false)
        setCommentAttachmentFileList([])
    }

    const showUserCartView = () => {
        setUserCartViewVisible(true)
    }

    const hideUserCartView = () => {
        setUserCartViewVisible(false)
    }

    const showHighProspectModal = (record) => {
        setHighProspectModalVisible(true)
        setExpectedSaleDate('')

        if (cart.highProspectData && cart.highProspectData.uid) {
            if (cart.highProspectData.expectedSaleDate) {
                setExpectedSaleDate(cart.highProspectData.expectedSaleDate)
                var expectedSaleDateMoment = dayjs(
                    cart.highProspectData.expectedSaleDate,
                    'DD-MMM-YYYY'
                )
            }

            setTimeout(() => {
                form.setFieldsValue({
                    active: cart.highProspectData.active,
                    expectedSale: cart.highProspectData.expectedSale,
                    expectedSaleDate: expectedSaleDateMoment,
                })
            }, 100)
        } else {
            setTimeout(() => {
                form.setFieldsValue({
                    active: true,
                })
            }, 100)
        }
    }

    const hideHighProspectModal = () => {
        setHighProspectModalVisible(false)
    }

    const showUpdateReminderDateModal = () => {
        setEditReminderVisible(true)
        setReminderDate('')
    }

    const hideUpdateReminderDateModal = () => {
        setEditReminderVisible(false)
        setReminderDate('')
    }

    const showAssignToModal = () => {
        if (adminUsers.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_ADMIN_USERS).then((res) => {
                setAdminUsers(res.data)
            })
        }

        setAssignToModalVisible(true)
    }

    const hideAssignToModal = () => {
        setAssignToModalVisible(false)
    }

    const showUpdateLeadOpenType = () => {
        if (openStatusTypes.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_OPEN_STATUS_TYPES).then(
                (res) => {
                    setOpenStatusTypes(res.data)
                }
            )
        }

        setUpdateLeadOpenTypeVisible(true)
        setOpenOrClose(cart.open)

        setTimeout(() => {
            getLeadSubTypes(cart.openType)

            setTimeout(() => {
                form.setFieldsValue({
                    type: cart.openType,
                    subType: cart.openSubType,
                })
            }, 100)
        }, 100)
    }

    const hideUpdateLeadOpenType = () => {
        setUpdateLeadOpenTypeVisible(false)
    }

    // const showOpenModal = () => {
    //     if (openStatusTypes.length === 0) {
    //         RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_OPEN_STATUS_TYPES).then(
    //             (res) => {
    //                 setOpenStatusTypes(res.data)
    //             }
    //         )
    //     }

    //     setOpenModalVisible(true)
    //     setOpenOrClose(true)
    //     setLeadSubTypes([])
    // }

    const hideOpenModal = () => {
        setOpenModalVisible(false)
        setOpenOrClose(cart.open)
    }

    // const showClosingCommentModal = () => {
    //     if (closedStatusTypes.length === 0) {
    //         RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_CLOSED_STATUS_TYPES).then(
    //             (res) => {
    //                 setClosedStatusTypes(res.data)
    //             }
    //         )
    //     }

    //     setClosingCommentModalVisible(true)
    //     setOpenOrClose(false)
    //     setLeadSubTypes([])
    // }

    const hideClosingCommentModal = () => {
        setClosingCommentModalVisible(false)
        setOpenOrClose(cart.open)
    }

    const updateLead = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                if (!values.subType) {
                    values.subType = ''
                }

                values.userId = userId
                values.open = openOrClose
                values.reminderDate = reminderDate

                if (!values.source) {
                    values.source = ''
                }

                if (!values.stream) {
                    values.stream = ''
                    values.level = ''
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.UPDATE_CART_LEAD, formData)
                    .then((response) => {
                        message.success(response.data)
                        hideUpdateCartModal()
                        getCart()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const hideUpdateCartModal = () => {
        setUpdateCartModalVisible(false)
    }

    const getLevelOptions = () => {
        var options = []
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const getStreamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const leadSourceOptions = () => {
        var options = []
        leadSources.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const getAdminUserOptions = () => {
        var options = []
        adminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    const leadSubTypeOptions = () => {
        var options = []
        leadSubTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.type}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const closedStatusTypeOptions = () => {
        var options = []
        closedStatusTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.type}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const openStatusTypeOptions = () => {
        var options = []
        openStatusTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.type}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const onChangeLeadType = (e) => {
        setSelectedLeadType(e.target.value)
        getLeadSubTypes(e.target.value)
        form.setFieldsValue({ subType: '' })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const openCloseOptions = () => {
        var options = []
        options.push(
            <Radio.Button value={true} key={'open'}>
                Open
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={false} key={'close'}>
                Close
            </Radio.Button>
        )

        return options
    }

    const onOpenCloseChange = (e) => {
        setOpenOrClose(e.target.value)
    }

    const addCallEntry = () => {
        form.validateFields(['callDurationMins', 'callDurationSecs'])
            .then((values) => {
                setLoading(true)

                values.userId = userId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.ADD_CALL_MADE_ENTRY_TO_CART_LEAD,
                    formData
                )
                    .then((response) => {
                        message.success(response.data)
                        getCartComments()
                        hideAddCallEntryModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const closeCart = () => {
        form.validateFields(['comments', 'type', 'subType'])
            .then((values) => {
                setLoading(true)
                setLoadingCart(true)

                var payload = {}
                payload.userId = userId
                payload.comments = values.comments
                payload.type = values.type
                payload.subType = values.subType ? values.subType : ''

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.CLOSE_CART_LEAD, formData)
                    .then(() => {
                        hideClosingCommentModal()
                        getCart()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const openLead = () => {
        form.validateFields(['type', 'subType'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.userId = userId
                payload.type = values.type
                payload.subType = values.subType ? values.subType : ''

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.OPEN_CART_LEAD, formData)
                    .then((response) => {
                        hideOpenModal()
                        getCart()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const updateLeadOpenType = () => {
        form.validateFields(['type', 'subType'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.userId = userId
                payload.type = values.type
                payload.subType = values.subType ? values.subType : ''

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.UPDATE_CART_LEAD_OPEN_TYPE, formData)
                    .then((response) => {
                        message.success(response.data)
                        getCart()
                        hideUpdateLeadOpenType()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const assignLead = () => {
        form.validateFields(['assignToUserId'])
            .then((values) => {
                setLoading(true)

                values.userId = userId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.ASSIGN_CART_LEAD, formData)
                    .then((response) => {
                        getCart()
                        hideAssignToModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const updateReminderDate = () => {
        if (!reminderDate) {
            message.error('Select Date!')
            return
        }

        setLoading(true)

        var payload = {}
        payload.userId = userId
        payload.reminderDate = reminderDate

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SET_CART_LEAD_REMINDER_DATE, formData)
            .then((response) => {
                var cart1 = cart
                cart1.reminderDate = reminderDate
                setCart(cart1)

                hideUpdateReminderDateModal()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const addCartComment = () => {
        form.validateFields(['comments'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.userId = userId
                payload.comments = values.comments

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                commentAttachmentFileList.forEach((file) => {
                    formData.append('file', file.originFileObj)
                })

                RestApi.doPost(GLOBAL.URL.ADD_COMMENTS_TO_CART_LEAD, formData)
                    .then((response) => {
                        getCartComments()
                        hideAddCartCommentModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showUpdateCartCommentDateModal = (record) => {
        setSelectedCartComment(record)
        setUpdateCartCommentDateModalVisible(true)
    }

    const hideUpdateCartCommentDateModal = () => {
        setSelectedCartComment({})
        setUpdateCartCommentDateModalVisible(false)
    }

    const updateCartCommentDate = () => {
        if (!updatedCartCommentDate) {
            message.error('Select Date!')
            return
        }

        setLoading(true)

        var payload = {}
        payload.id = selectedCartComment.id
        payload.date = updatedCartCommentDate

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.UPDATE_CART_LEAD_COMMENTS_DATE, formData)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                getCartComments()
                hideUpdateCartCommentDateModal()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showUpdateCartCommentDurationModal = (record) => {
        setSelectedCartComment(record)
        setUpdateCartCommentDurationModalVisible(true)

        setTimeout(() => {
            form.setFieldsValue({
                callDurationMins: record.callDurationMins,
                callDurationSecs: record.callDurationSecs,
            })
        }, 100)
    }

    const hideUpdateCartCommentDurationModal = () => {
        setSelectedCartComment({})
        setUpdateCartCommentDurationModalVisible(false)
    }

    const updateCartCommentDuration = () => {
        form.validateFields(['callDurationMins', 'callDurationSecs'])
            .then((values) => {
                setLoading(true)

                values.id = selectedCartComment.id

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.UPDATE_CART_LEAD_COMMENTS_DURATION,
                    formData
                )
                    .then((response) => {
                        message.destroy()
                        message.success(response.data)

                        getCartComments()
                        hideUpdateCartCommentDurationModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const pinUnpinLeadComment = (record) => {
        setPinUnpinLoading(true)

        var payload = {}
        payload.id = record.id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        var url = GLOBAL.URL.PIN_CART_LEAD_COMMENT
        if (record.pinned) {
            url = GLOBAL.URL.UNPIN_CART_LEAD_COMMENT
        }

        RestApi.doPost(url, formData)
            .then((res) => {
                getCartComments()
                message.success(res.data)
            })
            .finally(() => {
                setPinUnpinLoading(false)
            })
    }

    const deleteLeadComment = (id) => {
        setLoadingCart(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_CART_LEAD_COMMENTS, formData)
            .then((res) => {
                getCartComments()
                message.success(res.data)
            })
            .finally(() => {
                setLoadingCart(false)
            })
    }

    const refreshCloudPhoneCall = (record) => {
        setLoadingRefreshCloudPhoneCall(true)

        var payload = {}
        payload.id = record.cloudPhoneCall.id
        payload.leadType = 'internal'

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.CLOUD_PHONE.REFRESH_CALL_STATUS, postBody)
            .then((response) => {
                message.success(response.data)
                getCartComments()
            })
            .finally(() => {
                setLoadingRefreshCloudPhoneCall(false)
            })
    }

    const showPlayAudioModal = (url) => {
        setPlayAudioModalVisible(true)
        setAudioUrl(url)
    }

    const hidePlayAudioModal = () => {
        setPlayAudioModalVisible(false)
    }

    const pinUnpinLeadExternalComment = (record) => {
        setPinUnpinLeadExternalLoading(true)

        var payload = {}
        payload.uid = record.uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        var url = GLOBAL.URL.LEADS.PIN_LEAD_EXTERNAL_COMMENT
        if (record.pinned) {
            url = GLOBAL.URL.LEADS.UNPIN_LEAD_EXTERNAL_COMMENT
        }

        RestApi.doPost(url, formData)
            .then((res) => {
                getCartComments()
                message.success(res.data)
            })
            .finally(() => {
                setPinUnpinLeadExternalLoading(false)
            })
    }

    const deleteLeadExternalComment = (uid) => {
        setLoadingCart(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.LEADS.DELETE_LEAD_EXTERNAL_COMMENT, formData)
            .then((res) => {
                getCartComments()
                message.success(res.data)
            })
            .catch((error) => {
                setLoadingCart(false)
            })
    }

    const onChangeExpectedSaleDate = (value, dateString) => {
        setExpectedSaleDate(dateString ? dateString : '')
    }

    const updateHighProspectLead = () => {
        form.validateFields(['active', 'expectedSale'])
            .then((values) => {
                setLoadingCart(true)

                var payload = values
                payload.expectedSaleDate = expectedSaleDate

                if (cart.highProspectData && cart.highProspectData.uid) {
                    payload.uid = cart.highProspectData.uid
                } else {
                    payload.userId = cart.userId
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(
                    GLOBAL.URL.LEADS.UPDATE_HIGH_PROSPECT_LEAD,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideHighProspectModal()
                        getCart()
                    })
                    .catch((error) => {
                        setLoadingCart(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    return (
        <div style={{ marginTop: 10 }}>
            <div>
                <Button
                    type="primary"
                    size="small"
                    onClick={showUpdateCartModal}
                >
                    Update
                </Button>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={callLead}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="primary"
                        size="small"
                        style={{ marginLeft: 20 }}
                        loading={callUserLoading}
                    >
                        Call Lead
                    </Button>
                </Popconfirm>
                {cart.date ? (
                    <Button
                        type="default"
                        style={{ marginLeft: 20 }}
                        size="small"
                        onClick={showAddCallEntryModal}
                    >
                        Add Call Entry
                    </Button>
                ) : null}
                <SendWhatsAppToLead userData={userData} cartData={cart} />
                <span style={{ marginLeft: 20 }}>
                    <UserAcquisitionInfo userId={userId} />
                </span>
            </div>
            <Descriptions
                title=""
                bordered
                size="small"
                style={{ marginTop: 10 }}
            >
                <Descriptions.Item label="Status">
                    {cart.date ? (
                        <span>
                            {/* {cart.open ? (
                                <Button
                                    type="primary"
                                    onClick={showClosingCommentModal}
                                    size="small"
                                    loading={loading}
                                >
                                    Open
                                </Button>
                            ) : (
                                <Button
                                    type="default"
                                    onClick={showOpenModal}
                                    size="small"
                                    loading={loading}
                                >
                                    Closed
                                </Button>
                            )} */}
                            <span>
                                {cart.open ? (
                                    <Button
                                        type="link"
                                        onClick={showUpdateLeadOpenType}
                                        style={{ padding: 0 }}
                                    >
                                        <EditTwoTone style={{ fontSize: 11 }} />
                                    </Button>
                                ) : null}
                                <Tag color="purple" style={{ marginLeft: 5 }}>
                                    {cart.open
                                        ? cart.openType
                                        : cart.closedType}
                                </Tag>
                            </span>
                        </span>
                    ) : null}
                </Descriptions.Item>
                <Descriptions.Item label="Assigned To">
                    {cart.assignedTo && cart.assignedTo.id ? (
                        <>
                            <Button
                                type="link"
                                onClick={showAssignToModal}
                                style={{ padding: 0 }}
                            >
                                <EditTwoTone style={{ fontSize: 11 }} />
                            </Button>
                            <b style={{ marginLeft: 5 }}>
                                {cart.assignedTo
                                    ? cart.assignedTo.firstName
                                    : ''}
                            </b>
                        </>
                    ) : cart.date ? (
                        <Button size="small" onClick={showAssignToModal}>
                            Assign
                        </Button>
                    ) : null}
                </Descriptions.Item>
                <Descriptions.Item label="Date">{cart.date}</Descriptions.Item>
                <Descriptions.Item label="Coupon">
                    {cart.coupon
                        ? cart.coupon.couponName +
                          ' (' +
                          cart.coupon.discount +
                          cart.coupon.type +
                          ')'
                        : null}
                </Descriptions.Item>
                <Descriptions.Item label="Source">
                    {cart.source}
                </Descriptions.Item>
                <Descriptions.Item label="Follow Up">
                    <span>{cart.reminderDate ? cart.reminderDate : '-'}</span>
                    {cart.date ? (
                        <Button
                            type="default"
                            size="small"
                            onClick={showUpdateReminderDateModal}
                            style={{ marginLeft: 10 }}
                        >
                            Update
                        </Button>
                    ) : null}
                </Descriptions.Item>
                <Descriptions.Item label="Prospect">
                    <span>
                        {cart.leadCreated ? (
                            <>
                                {cart.highProspectData &&
                                cart.highProspectData.active ? (
                                    <span>
                                        <Tag color="red">{'High Prospect'}</Tag>
                                        {cart.highProspectData.expectedSale ? (
                                            <>
                                                <br />
                                                {
                                                    cart.highProspectData
                                                        .expectedSaleString
                                                }
                                            </>
                                        ) : null}
                                        {cart.highProspectData
                                            .expectedSaleDate ? (
                                            <>
                                                <br />
                                                {
                                                    cart.highProspectData
                                                        .expectedSaleDate
                                                }
                                            </>
                                        ) : null}
                                    </span>
                                ) : null}
                                <br />
                                <Button
                                    type="link"
                                    style={{
                                        padding: 0,
                                        fontSize: 10,
                                    }}
                                    onClick={showHighProspectModal}
                                >
                                    Update Prospect
                                </Button>
                            </>
                        ) : null}
                    </span>
                </Descriptions.Item>
                <Descriptions.Item label="Course Stream">
                    {cart.stream ? cart.stream + ' - ' + cart.level : ''}
                </Descriptions.Item>
            </Descriptions>
            <div style={{ marginTop: 10 }}>
                <b>Total Cart Value: {cart.totalMrp}</b>
                <Button
                    type="default"
                    size="small"
                    onClick={showUserCartView}
                    style={{ marginLeft: 20 }}
                >
                    User Cart View
                </Button>
            </div>
            <Table
                loading={loadingCart}
                columns={cartItemsTableColumns}
                dataSource={cart.cartItems}
                pagination={false}
                style={{ marginTop: 8 }}
                size="small"
                rowKey="cnt"
            />
            <div>
                <h4 style={{ marginTop: 10 }}>Comments</h4>
                {cart.date ? (
                    <>
                        <Button
                            type="primary"
                            onClick={showAddCartCommentModal}
                        >
                            Add
                        </Button>
                        {cartCommentsPinned.length +
                        leadExternalCommentsPinned.length ? (
                            <Radio.Group
                                value={selectedCommentFilter}
                                onChange={(e) =>
                                    setSelectedCommentFilter(e.target.value)
                                }
                                style={{ marginLeft: 10 }}
                            >
                                <Radio.Button
                                    value={COMMENT_FILTER_ALL}
                                    key={COMMENT_FILTER_ALL}
                                >
                                    All
                                </Radio.Button>
                                <Radio.Button
                                    value={COMMENT_FILTER_PINNED}
                                    key={COMMENT_FILTER_PINNED}
                                >
                                    {'Pinned (' +
                                        (cartCommentsPinned.length +
                                            leadExternalCommentsPinned.length) +
                                        ')'}
                                </Radio.Button>
                            </Radio.Group>
                        ) : null}
                        <Button type="link" onClick={getCartComments}>
                            {loadingCartComments ? (
                                <LoadingOutlined style={{ fontSize: 20 }} />
                            ) : (
                                <ReloadOutlined style={{ fontSize: 20 }} />
                            )}
                        </Button>
                    </>
                ) : null}
                <Table
                    loading={loadingCartComments}
                    columns={cartCommentsColumns}
                    dataSource={
                        selectedCommentFilter === COMMENT_FILTER_ALL
                            ? cartComments
                            : cartCommentsPinned
                    }
                    pagination={false}
                    style={{ marginTop: 8 }}
                    size="small"
                    rowKey="cnt"
                />
                {cart && cart.leadExternal && cart.leadExternal.leadId ? (
                    <>
                        <h4 style={{ marginTop: 10 }}>
                            Lead External Comments
                        </h4>
                        <Table
                            columns={leadExternalCommentsColumns}
                            dataSource={
                                selectedCommentFilter === COMMENT_FILTER_ALL
                                    ? leadExternalComments
                                    : leadExternalCommentsPinned
                            }
                            pagination={false}
                            style={{ marginTop: 8 }}
                            size="small"
                            rowKey="cnt"
                        />
                        {leadExternalCourses.length === 0 ? null : (
                            <>
                                <h4 style={{ marginTop: 10 }}>
                                    Lead External Interested Courses
                                </h4>
                                <Table
                                    columns={leadExternalCourseColumns}
                                    dataSource={leadExternalCourses}
                                    pagination={false}
                                    size="small"
                                    style={{ marginTop: 10 }}
                                    rowKey="uid"
                                />
                            </>
                        )}
                    </>
                ) : null}
            </div>
            <Modal
                title={'Update Lead'}
                open={updateCartModalVisible}
                onOk={updateLead}
                confirmLoading={loading}
                onCancel={hideUpdateCartModal}
                okText={'Update'}
                destroyOnClose={true}
                width={1000}
            >
                <div style={{ marginLeft: 75, marginBottom: 24 }}>
                    <span>Lead Status: </span>
                    <Radio.Group
                        onChange={onOpenCloseChange}
                        value={openOrClose}
                    >
                        {openCloseOptions()}
                    </Radio.Group>
                </div>
                <Form
                    form={form}
                    layout="horizontal"
                    initialValues={{ callDurationMins: 0, callDurationSecs: 0 }}
                    {...formItemLayout}
                >
                    <Form.Item
                        label="Lead Type"
                        style={{ marginBottom: 5 }}
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group onChange={onChangeLeadType}>
                            {openOrClose
                                ? openStatusTypeOptions()
                                : closedStatusTypeOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {leadSubTypes.length > 0 ? (
                        <Form.Item
                            label="Lead Sub Type"
                            style={{ marginBottom: 5 }}
                            name="subType"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Radio.Group>{leadSubTypeOptions()}</Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="Assign To"
                        style={{ marginBottom: 5 }}
                        name="assignToUserId"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Asignee"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAdminUserOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Reminder"
                        style={{ marginBottom: 5 }}
                        name="reminderDate"
                        rules={[
                            {
                                required:
                                    selectedLeadType ===
                                    LEAD_TYPE_FUTURE_PROSPECT,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="Select Date"
                            format="DD-MMM-YY HH:mm"
                            showTime
                            onChange={(value, dateString) => {
                                setReminderDate(dateString)
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Source"
                        style={{ marginBottom: 5 }}
                        name="source"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group>{leadSourceOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Course Stream"
                        style={{ marginBottom: 0 }}
                        name="stream"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group
                            onChange={(e) => handleCourseChange(e.target.value)}
                        >
                            {getStreamOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {selectedCourse ? (
                        <Form.Item
                            label="Course Level"
                            style={{ marginBottom: 0 }}
                            name="level"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Radio.Group placeholder="Select Level">
                                {getLevelOptions()}
                            </Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="Call Mins"
                        style={{ marginBottom: 5 }}
                        name="callDurationMins"
                        valuePropName="value"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Call Secs"
                        style={{ marginBottom: 5 }}
                        name="callDurationSecs"
                        valuePropName="value"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={59} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Comment"
                        style={{ marginBottom: 5 }}
                        name="comment"
                        valuePropName="value"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea placeholder="Comment" />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Add Call Entry'}
                open={addCallEntryModalVisible}
                onOk={addCallEntry}
                confirmLoading={loading}
                onCancel={hideAddCallEntryModal}
                okText={'Add'}
                destroyOnClose={true}
            >
                <h4>{userData.firstName + ' ' + userData.lastName}</h4>
                <Form
                    form={form}
                    initialValues={{ callDurationMins: 0, callDurationSecs: 0 }}
                    layout="horizontal"
                    {...formItemLayout}
                >
                    <Form.Item
                        label="Call Mins"
                        style={{ marginBottom: 5 }}
                        name="callDurationMins"
                        valuePropName="value"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Call Secs"
                        style={{ marginBottom: 5 }}
                        name="callDurationSecs"
                        valuePropName="value"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Closing comments'}
                open={closingCommentModalVisible}
                onOk={closeCart}
                confirmLoading={loading}
                onCancel={hideClosingCommentModal}
                okText={'Close'}
                destroyOnClose={true}
                width={800}
            >
                <div>
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Comments"
                            name="comments"
                            valuePropName="value"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TextArea placeholder="Comments" autoFocus />
                        </Form.Item>
                        <Form.Item
                            label="Lead Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Radio.Group onChange={onChangeLeadType}>
                                {closedStatusTypeOptions()}
                            </Radio.Group>
                        </Form.Item>
                        {leadSubTypes.length > 0 ? (
                            <Form.Item
                                label="Lead Sub Type"
                                name="subType"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    {leadSubTypeOptions()}
                                </Radio.Group>
                            </Form.Item>
                        ) : null}
                    </Form>
                </div>
            </Modal>
            <Modal
                title={'Open Lead'}
                open={openModalVisible}
                onOk={openLead}
                confirmLoading={loading}
                onCancel={hideOpenModal}
                okText={'Open'}
                destroyOnClose={true}
                width={800}
            >
                <div>
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Lead Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Radio.Group onChange={onChangeLeadType}>
                                {openStatusTypeOptions()}
                            </Radio.Group>
                        </Form.Item>
                        {leadSubTypes.length > 0 ? (
                            <Form.Item
                                label="Lead Sub Type"
                                name="subType"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    {leadSubTypeOptions()}
                                </Radio.Group>
                            </Form.Item>
                        ) : null}
                    </Form>
                </div>
            </Modal>
            <Modal
                title={'Update Lead Type'}
                open={updateLeadOpenTypeVisible}
                onOk={updateLeadOpenType}
                confirmLoading={loading}
                onCancel={hideUpdateLeadOpenType}
                okText={'Update'}
                destroyOnClose={true}
                width={800}
            >
                <div>
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Lead Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Radio.Group onChange={onChangeLeadType}>
                                {openStatusTypeOptions()}
                            </Radio.Group>
                        </Form.Item>
                        {leadSubTypes.length > 0 ? (
                            <Form.Item
                                label="Lead Sub Type"
                                name="subType"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    {leadSubTypeOptions()}
                                </Radio.Group>
                            </Form.Item>
                        ) : null}
                    </Form>
                </div>
            </Modal>
            <Modal
                title={'Assign Lead'}
                open={assignToModalVisible}
                onOk={assignLead}
                confirmLoading={loading}
                onCancel={hideAssignToModal}
                okText={'Assign'}
                destroyOnClose={true}
            >
                {cart.assignedTo ? (
                    <div style={{ marginBottom: 10 }}>
                        Current assignee:{' '}
                        <strong>{cart.assignedTo.firstName}</strong>
                    </div>
                ) : null}
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Assign To"
                        name="assignToUserId"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Filter By Asignee"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAdminUserOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Set Follow Up Date'}
                open={editReminderVisible}
                onOk={updateReminderDate}
                confirmLoading={loading}
                onCancel={hideUpdateReminderDateModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                <div>
                    <div>
                        Current follow up:{' '}
                        <strong>
                            {cart.userId ? cart.reminderDate : '-NA-'}
                        </strong>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <span style={{ marginRight: 10 }}>Select date: </span>
                        <DatePicker
                            placeholder="Select Date"
                            format="DD-MMM-YY HH:mm"
                            onChange={(value, dateString) => {
                                setReminderDate(dateString)
                            }}
                            showTime
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                open={userCartViewVisible}
                title={'User Cart View'}
                onCancel={hideUserCartView}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <UserCartView userId={userId} />
            </Modal>
            <Modal
                title={'Add Comment'}
                open={addCartCommentModalVisible}
                onOk={addCartComment}
                confirmLoading={loading}
                onCancel={hideAddCartCommentModal}
                okText={'Add Comment'}
                destroyOnClose={true}
                width={800}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    {...formItemLayout}
                    style={{ marginTop: 20 }}
                >
                    <Form.Item
                        label="Comments"
                        name="comments"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <TextArea
                            placeholder="Comments"
                            autoFocus
                            onPaste={(e) =>
                                processClipboardAttachment(
                                    e,
                                    setCommentAttachmentFileList
                                )
                            }
                        />
                    </Form.Item>
                </Form>
                <ILAttachmentFileList
                    attachmentFileList={commentAttachmentFileList}
                    setAttachmentFileList={setCommentAttachmentFileList}
                />
            </Modal>
            <Modal
                title={'Update Comment Date'}
                open={updateCartCommentDateModalVisible}
                onOk={updateCartCommentDate}
                confirmLoading={loading}
                onCancel={hideUpdateCartCommentDateModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                <div>
                    <div>
                        Current date:{' '}
                        <strong>{selectedCartComment.date}</strong>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <span style={{ marginRight: 10 }}>Select date: </span>
                        <DatePicker
                            placeholder="Select Date"
                            format="DD-MMM-YY HH:mm"
                            showTime
                            onChange={(value, dateString) => {
                                setUpdatedCartCommentDate(dateString)
                            }}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                title={'Update Duration'}
                open={updateCartCommentDurationModalVisible}
                onOk={updateCartCommentDuration}
                confirmLoading={loading}
                onCancel={hideUpdateCartCommentDurationModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                {updateCartCommentDurationModalVisible ? (
                    <Form
                        form={form}
                        initialValues={{
                            callDurationMins: 0,
                            callDurationSecs: 0,
                        }}
                        layout="horizontal"
                        {...formItemLayout}
                    >
                        <Form.Item
                            label="Call Mins"
                            style={{ marginBottom: 5 }}
                            name="callDurationMins"
                            valuePropName="value"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber min={0} max={100} step={1} autoFocus />
                        </Form.Item>
                        <Form.Item
                            label="Call Secs"
                            style={{ marginBottom: 5 }}
                            name="callDurationSecs"
                            valuePropName="value"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber min={0} max={59} step={1} />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                open={playAudioModalVisible}
                title={'Phone Call Recording'}
                onCancel={hidePlayAudioModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <ReactAudioPlayer src={audioUrl} autoPlay controls />
            </Modal>
            <Modal
                title={'High Prospect Lead'}
                open={highProspectModalVisible}
                onOk={updateHighProspectLead}
                confirmLoading={loadingCart}
                onCancel={hideHighProspectModal}
                okText={'Update'}
                destroyOnClose={true}
                width={800}
            >
                {highProspectModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Active"
                            style={{ marginBottom: 10 }}
                            name="active"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio.Button value={true} key={'Yes'}>
                                    {'Yes'}
                                </Radio.Button>
                                <Radio.Button value={false} key={'No'}>
                                    {'No'}
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="Expected Sale"
                            style={{ marginBottom: 10 }}
                            name="expectedSale"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber autoFocus min={0} />
                        </Form.Item>
                        <Form.Item
                            label="Expected Sale Date"
                            style={{ marginBottom: 10 }}
                            name="expectedSaleDate"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <DatePicker
                                placeholder="Select Date"
                                format="DD-MMM-YYYY"
                                onChange={onChangeExpectedSaleDate}
                            />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}

export default UserCart
